import React, { useState } from 'react';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { colours, Button, Menu, Modal, Dropdown, Row, Col, Input, message } from '@a-cloud-guru/rainbow-ui';
import * as LabStyles from 'components/labsstyles';
import { Page } from 'components/page/Page';
import { Dashboard } from 'components/dashboard/Dashboard';
import { Pagination } from './Pagination';
import { Link } from 'react-router-dom';

export const CREATE_METADATA = gql`
  mutation ACG_putAssessmentMetadata($input: AssessmentMetadataInput!) {
    ACG_putAssessmentMetadata(input: $input) {
      roles {
        ...metadataFragment
      }
      services {
        ...metadataFragment
      }
      technologies {
        ...metadataFragment
      }
      topics {
        ...metadataFragment
      }
    }
  }
  fragment metadataFragment on AssessmentMetadataValue {
    id
    value
  }
`;


export const Questions = props => {
  const [toggleTopicModal, setToggleTopicModal] = useState(false);
  const [toggleServiceModal, setToggleServiceModal] = useState(false);
  const [toggleTechnologyModal, setToggleTechnologyModal] = useState(false);
  const [toggleRoleModal, setToggleRoleModal] = useState(false);
  const [topicText, setTopicText] = useState('');
  const [serviceText, setServiceText] = useState('');
  const [technologyText, setTechnologyText] = useState('');
  const [roleText, setRoleText] = useState('');

  const { client } = props;

  const showTopicModal = () => {
    setToggleTopicModal(true);
  };

  const onTopicConfirm = e => {
    if (!isEmpty(topicText)) {
      props.client
        .mutate({
          mutation: CREATE_METADATA,
          variables: { input: { value: topicText, type: "TOPIC" } }
        })
        .then(() => message.success('New topic added'))
        .catch(() => message.error('Something went wrong'));
    }
    setTopicText('');
    setToggleTopicModal(false);
  };

  const onTopicCancel = e => {
    setTopicText('');
    setToggleTopicModal(false);
  };

  const showServiceModal = () => {
    setToggleServiceModal(true);
  };

  const onServiceConfirm = e => {
    if (!isEmpty(serviceText)) {
      props.client
        .mutate({
          mutation: CREATE_METADATA,
          variables: { input: { value: serviceText, type: "SERVICE" } }
        })
        .then(() => message.success('New service added'))
        .catch(() => message.error('Something went wrong'));
    }
    setServiceText('');
    setToggleServiceModal(false);
  };

  const onServiceCancel = e => {
    setServiceText('');
    setToggleServiceModal(false);
  };

  const showTechnologyModal = () => {
    setToggleTechnologyModal(true);
  };

  const onTechnologyConfirm = e => {
    if (!isEmpty(technologyText)) {
      props.client
        .mutate({
          mutation: CREATE_METADATA,
          variables: { input: { value: technologyText, type: "TECHNOLOGY" } }
        })
        .then(() => message.success('New technology added'))
        .catch(() => message.error('Something went wrong'));
    }
    setTechnologyText('');
    setToggleTechnologyModal(false);
  };

  const onTechnologyCancel = e => {
    setTechnologyText('');
    setToggleTechnologyModal(false);
  };

  const showRoleModal = () => {
    setToggleRoleModal(true);
  };

  const onRoleConfirm = e => {
    if (!isEmpty(roleText)) {
      props.client
        .mutate({
          mutation: CREATE_METADATA,
          variables: { input: { value: roleText, type: "ROLE" } }
        })
        .then(() => message.success('New role added'))
        .catch(() => message.error('Something went wrong'));
    }
    setRoleText('');
    setToggleRoleModal(false);
  };

  const onRoleCancel = e => {
    setRoleText('');
    setToggleRoleModal(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <span onClick={showTopicModal}>
          New Topic
          </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span onClick={showTechnologyModal}>
          New Technology
          </span>
      </Menu.Item>
      <Menu.Item key="3">
        <span onClick={showRoleModal}>
          New Role
          </span>
      </Menu.Item>
      <Menu.Item key="4">
        <span onClick={showServiceModal}>
          New Service
          </span>
      </Menu.Item>
    </Menu>
  );

  const breadcrumbs = [{
    text: 'Questions'
  }]

  return (
    <>
      <Page title="Question Engine" description="" breadcrumbs={breadcrumbs}>
        <Row gutter={30}>
          <Col span={24}>
            <Dashboard
              title="Question Bank"
              description="Manage questions and answers for assessments."
              button={
                <>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button>
                      New Options <i className="fa fa-fw fa-caret-down" />
                    </Button>
                  </Dropdown>
                  <Button type="positive">
                    <Link to={`/question/new`}>
                      <i className="fa fa-fw fa-plus" /> New Question
                    </Link>
                  </Button>
                </>
              }
            >
              <Pagination apolloClient={client} />
            </Dashboard>
          </Col>
        </Row>
      </Page>
      <Modal
        title={<LabStyles.ModalTitle>New Topic</LabStyles.ModalTitle>}
        okText="Submit"
        visible={toggleTopicModal}
        onOk={onTopicConfirm}
        okButtonProps={{
          disabled: isEmpty(topicText)
        }}
        onCancel={onTopicCancel}
        footer={[
          <Button key="back" onClick={onTopicCancel}>
            Cancel
          </Button>,
          <Button
            type="positive"
            key="save"
            onClick={onTopicConfirm}
            disabled={isEmpty(topicText)}
          >
            Submit
          </Button>
        ]}
      >
        <NameLabel>Topic name: </NameLabel>
        <StyledInput
          value={topicText}
          placeholder={'New topic here'}
          onChange={e => setTopicText(e.target.value)}
        />
      </Modal>
      <Modal
        title={<LabStyles.ModalTitle>New Service</LabStyles.ModalTitle>}
        okText="Submit"
        visible={toggleServiceModal}
        onOk={onServiceConfirm}
        okButtonProps={{
          disabled: isEmpty(serviceText)
        }}
        onCancel={onServiceCancel}
        footer={[
          <Button key="back" onClick={onServiceCancel}>
            Cancel
          </Button>,
          <Button
            type="positive"
            key="save"
            onClick={onServiceConfirm}
            disabled={isEmpty(serviceText)}
          >
            Submit
          </Button>
        ]}
      >
        <NameLabel>Service name: </NameLabel>
        <StyledInput
          value={serviceText}
          placeholder={'New service here'}
          onChange={e => setServiceText(e.target.value)}
        />
      </Modal>
      <Modal
        title={<LabStyles.ModalTitle>New Technology</LabStyles.ModalTitle>}
        okText="Submit"
        visible={toggleTechnologyModal}
        onOk={onTechnologyConfirm}
        okButtonProps={{
          disabled: isEmpty(technologyText)
        }}
        onCancel={onTechnologyCancel}
        footer={[
          <Button key="back" onClick={onTechnologyCancel}>
            Cancel
          </Button>,
          <Button
            type="positive"
            key="save"
            onClick={onTechnologyConfirm}
            disabled={isEmpty(technologyText)}
          >
            Submit
          </Button>
        ]}
      >
        <NameLabel>Technology name: </NameLabel>
        <StyledInput
          value={technologyText}
          placeholder={'New technology here'}
          onChange={e => setTechnologyText(e.target.value)}
        />
      </Modal>
      <Modal
        title={<LabStyles.ModalTitle>New Role</LabStyles.ModalTitle>}
        okText="Submit"
        visible={toggleRoleModal}
        onOk={onRoleConfirm}
        okButtonProps={{
          disabled: isEmpty(roleText)
        }}
        onCancel={onRoleCancel}
        footer={[
          <Button key="back" onClick={onRoleCancel}>
            Cancel
          </Button>,
          <Button
            type="positive"
            key="save"
            onClick={onRoleConfirm}
            disabled={isEmpty(roleText)}
          >
            Submit
          </Button>
        ]}
      >
        <NameLabel>Role name: </NameLabel>
        <StyledInput
          value={roleText}
          placeholder={'New Role here'}
          onChange={e => setRoleText(e.target.value)}
        />
      </Modal>
    </>
  );
};

const NameLabel = styled.div`
  color: ${colours.navy700};
  font-size: 1em;
  font-weight: 600;
`;

const StyledInput = styled(Input)`
  margin-top: 1em;
`;
