const Joi = require('joi');

const schema = Joi.object()
  .keys({
    AUTH0_CLIENT_ID: Joi.string().required(),
    AUTH0_DOMAIN: Joi.string().required(),
    AUTH0_CUSTOM_DOMAIN: Joi.string().required(),
    AUTH0_API_AUDIENCE: Joi.string().required(),  
    GRAPHQL_API_ENDPOINT: Joi.string().required(),
    DOMAIN: Joi.string().required(),
    CLOUDINARY_ACCOUNT: Joi.string().required(),
    CLOUDINARY_BASE_URL: Joi.string().required(),
    SPLIT_API_KEY: Joi.string(),
    STAGE: Joi.string(),
    BUILD_NUMBER: Joi.string(),
    ALGOLIA_APP_ID: Joi.string().required(),
    ALGOLIA_STAGE: Joi.string(),
    ALGOLIA_SEARCH_ONLY_API_KEY: Joi.string()
  })
  .required();

module.exports = { schema };
