import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/bootstrap/css/bootstrap.min.css';
import 'antd/dist/antd.less';
import '@a-cloud-guru/rainbow-ui/lib/fonts.less';
import '@fortawesome/fontawesome-free/css/all.css';
import './index.css';
import App from './App';
import { ApolloProvider } from 'react-apollo';
import { getApolloClient } from 'lib/make-apollo-client';
import { ConfigProvider, defaultTheme } from '@a-cloud-guru/rainbow-ui';

const client = getApolloClient();

ReactDOM.render(
  <ConfigProvider theme={defaultTheme}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </ConfigProvider>,
  document.getElementById('root')
);
