import Joi from 'joi';
import { isEmpty } from 'lodash';

const optionalNullEmptyString = Joi.string()
  .allow('')
  .allow(null)
  .optional();

const answerSchema = Joi.object().keys({
  answerId: optionalNullEmptyString,
  text: optionalNullEmptyString,
  correct: Joi.boolean().optional()
});

const explanationLinksSchema = Joi.object().keys({
  text: optionalNullEmptyString,
  url: optionalNullEmptyString
});

const explanationSchema = Joi.object()
  .keys({
    text: optionalNullEmptyString,
    explanationLinks: Joi.array().items(explanationLinksSchema)
  })
  .allow(null)
  .optional();

const disciplineSchema = Joi.array().items(
  Joi.object().keys({
    role: optionalNullEmptyString,
    skillLevel: optionalNullEmptyString
  })
);

export const importedQuestionSchema = Joi.object().keys({
  questionId: Joi.string().required(),
  questionText: Joi.string().required(),
  editorNote: optionalNullEmptyString,
  explanation: explanationSchema,
  answers: Joi.array().items(answerSchema),
  imageUrl: optionalNullEmptyString,
  disciplines: disciplineSchema,
  assessmentType: Joi.array().items(optionalNullEmptyString),
  topics: Joi.array().items(optionalNullEmptyString),
  cloudProviders: Joi.array().items(optionalNullEmptyString),
  technologies: Joi.array().items(optionalNullEmptyString),
  services: Joi.array().items(optionalNullEmptyString)
});

export const importedQuestionArraySchema = Joi.array().items(
  importedQuestionSchema
);

export const isQuestionValid = question => {
  const error = Joi.validate(question, importedQuestionSchema).error;
  if (!isEmpty(error)) {
    console.log('Question Validation Error: ', error);
    console.log('question: ', question);
  }
  return isEmpty(error);
};

export const isQuestionArrayValid = questionArray => {
  const error = Joi.validate(questionArray, importedQuestionArraySchema).error;
  return isEmpty(error);
};
