import gql from 'graphql-tag';
import { get } from 'lodash';

export const GET_ASSESSMENT_METADATA = gql`
  query getAssessmentMetadata {
    getAssessmentMetadata {
      roles {
        ...metadataFragment
      }
      services {
        ...metadataFragment
      }
      technologies {
        ...metadataFragment
      }
      topics {
        ...metadataFragment
      }
    }
  }
  fragment metadataFragment on AssessmentMetadataValue {
    id
    value
  }
`;

const getAssessmentMetadata = apolloClient => {
  if (!apolloClient) {
    return {};
  }

  return apolloClient
    .query({
      query: GET_ASSESSMENT_METADATA,
      fetchPolicy: 'no-cache'
    })
    .then(res => {
      if (!res || !res.data) {
        return {};
      }
      const result = get(res.data, 'getAssessmentMetadata', {});
      return result;
    })
    .catch(error => {
      console.log('getAssessmentMetadata error: ', error);
      return {};
    });
};

export { getAssessmentMetadata };
