import React, { useState } from 'react';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { get, isEmpty, uniq } from 'lodash';
import { Button, Input, message } from '@a-cloud-guru/rainbow-ui';
import { AddTagBtn, ErrorMsgContainer, StyledModal, ModalTitle, ModalDesc, ModalContent } from 'components/tag/styled';

const ERROR_TYPES = {
  UNKNOWN: 'UNKNOWN',
  TAGS_EXIST: 'TAGS_EXIST'
};

export const CREATE_TAGS = gql`
  mutation ACG_createContentTags($tags: [ContentTagCreateInput!]) {
    ACG_createContentTags(tags: $tags) {
      ... on CreateTagResults {
        createdTags {
          tagId
          label
          category
          lastModifiedDate
        }
      }

      ... on ContentTagExistedError {
        existingTags
      }
    }
  }
`;

export const AddTags = props => {
  const [tags, setTags] = useState('');
  const [error, setError] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [createTags] = useMutation(CREATE_TAGS);

  const { category } = props;
  const categoryName = get(category, 'name', '');
  const categoryId = get(category, 'id', '');

  const showModal = () => {
    setToggleModal(true);
  };

  const reset = () => {
    setTags('');
    setError(null);
    setToggleModal(false);
    setLoading(false);
  };

  const onTagsExist = existingTags => {
    message.error('Something went wrong.');
    const existingTagError = makeExistingTagError(existingTags);
    setError(existingTagError);
    setLoading(false);
    return;
  };

  const onCreated = () => {
    message.success('Tags created.');
    reset();
    return props.history.push(`/tags/${categoryId}`);
  };

  const onError = err => {
    console.log(err);
    const msg = 'Something went wrong.';
    message.error(msg);
    setError({
      title: 'Oopppsss....',
      value: msg,
      type: ERROR_TYPES.UNKNOWN
    });
    setLoading(false);
    return;
  };

  const onCancel = e => {
    reset();
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    if (!isEmpty(categoryName)) {
      const tagsToCreate = makeTagsForCategory({ tags, categoryName });

      return createTags({ variables: { tags: tagsToCreate } })
        .then(({ data }) => {
          const existingTags = get(data, 'ACG_createContentTags.existingTags', []);

          if (!isEmpty(existingTags)) {
            return onTagsExist(existingTags);
          }

          const createdTags = get(data, 'ACG_createContentTags.createdTags', []);

          if (!isEmpty(createdTags)) {
            return onCreated();
          }
        })
        .catch(onError);
    }
  };

  const displayError = () => {
    if (isEmpty(error)) {
      return null;
    }

    const errorTitle = get(error, 'title', '');
    const errorValue = get(error, 'value', '');

    if (error.type === ERROR_TYPES.UNKNOWN) {
      return <ErrorMsgContainer title={errorTitle} message={errorValue} />;
    }

    if (error.type === ERROR_TYPES.TAGS_EXIST) {
      const existingTags = errorValue.join(', ');
      const message = (
        <div>
          {`The tag${errorValue.length > 1 ? 's' : ''} `}
          <strong>{existingTags}</strong>
          {errorValue.length > 1 ? ' are ' : ' is '}
          {'already in use. No tags were created.'}
        </div>
      );

      return <ErrorMsgContainer title={errorTitle} message={message} />;
    }

    return null;
  };

  const getButtonText = () => {
    if (isEmpty(tags)) {
      return 'Add Tags';
    }

    const noOfTags = getUniqueTags(tags).length || 0;
    const tagCountText = noOfTags > 1 ? `${noOfTags} Tags` : `${noOfTags} Tag`;

    if (loading) {
      return noOfTags > 1 ? `Adding ${tagCountText}` : `Adding ${tagCountText}`;
    }

    return `Add ${tagCountText}`;
  };

  return (
    <>
      <AddTagBtn type='primary' onClick={showModal}>
        Add Tags
      </AddTagBtn>
      <StyledModal
        destroyOnClose
        title={<ModalTitle>Add Tags</ModalTitle>}
        visible={toggleModal}
        okButtonProps={{
          disabled: isEmpty(tags)
        }}
        onCancel={onCancel}
        footer={[
          <Button key='back' onClick={onCancel}>
            Cancel
          </Button>,
          <Button type='primary' key='save' onClick={onSubmit} disabled={isEmpty(tags) || loading}>
            {getButtonText()}
          </Button>
        ]}
      >
        <ModalDesc>Use the input below to add one or more tags. We support comma separated values.</ModalDesc>
        {displayError()}
        <ModalContent>
          <Input.TextArea data-testid='add-tags-input' value={tags} autoSize={{ minRows: 10, maxRows: 50 }} placeholder={'Enter tag(s)'} onChange={e => setTags(e.target.value)} />
        </ModalContent>
      </StyledModal>
    </>
  );
};

export const AddTagsModal = withRouter(AddTags);

export const makeTagsForCategory = ({ tags, categoryName }) => {
  const unique = getUniqueTags(tags);

  return unique.map(label => ({
    label,
    category: categoryName
  }));
};

const getUniqueTags = tags => {
  return uniq(tags.split(',').map(tag => tag.trim())).filter(Boolean);
};

const makeExistingTagError = (existingTags = []) => {
  const title = existingTags.length > 1 ? 'Tags in use' : 'Tag in use';

  return { title, value: existingTags, type: ERROR_TYPES.TAGS_EXIST };
};
