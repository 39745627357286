import React from 'react';
import { QUESTION_STATUS } from './constants';
import * as Styles from 'components/questions-styles';

export const QuestionStatus = props => {
  const { status } = props;

  if (status === QUESTION_STATUS.DRAFT) {
    return <Styles.VisibilityDraftBox />;
  }

  if (status === QUESTION_STATUS.QA) {
    return <Styles.VisibilityInQABox />;
  }

  if (status === QUESTION_STATUS.LIVE) {
    return <Styles.VisibilityLiveBox />;
  }

  if (status === QUESTION_STATUS.HIDDEN) {
    return <Styles.VisibilityHiddenBox />;
  }

  if (status === QUESTION_STATUS.DECOMMISSIONED) {
    return <Styles.VisibilityDecommissionedBox />;
  }

  return null;
};
