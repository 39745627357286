import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { TAG_CATEGORY } from 'components/tag/constants';
import { TagCategoryRow } from 'components/tag/TagCategoryRow';
import { colours } from '@a-cloud-guru/rainbow-ui';

export const TagCategoryList = props => {

  const makeRows = () => {
    return map(TAG_CATEGORY, category => <TagCategoryRow key={category.id} category={category} />);
  };

  return (
    <Container>
      {makeRows()}
    </Container>
  )
};

const Container = styled.div`
  border-top: 1px solid ${colours.lightGrey600};

  > * {
    border-bottom: 1px solid ${colours.lightGrey600};
  }
`;