import React from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { colours, Table, Flexbox, Box } from '@a-cloud-guru/rainbow-ui';
import { useContentTags } from 'lib/hooks/useContentTags';

export const TagsTable = props => {
  const { category } = props;
  const { tags } = useContentTags(category);

  const columns = [
    {
      title: 'Display Value',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagId',
      key: 'tagId',
    },
    {
      title: 'Number of Uses',
      dataIndex: 'count',
      sorter: (a, b) => a.count > b.count,
      render: (text, record) => (
        <div>Coming soon</div>
      )
    },
    {
      title: 'Last modified',
      dataIndex: 'lastModifiedDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.count - b.count,
      render: (text) => (
        <div>{moment(text).format('DD/MM/YYYY HH:MM')}</div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <Box>
          <Flexbox alignItems="center">
            <Box>
              <ActionLink blue={true} onClick={() => onRenameClick(record.objectID)}>Rename</ActionLink>
            </Box>
            <Box ml="s5">
              <ActionLink red={true} onClick={() => onArchiveClick(record.objectID)}>Archive</ActionLink>
            </Box>
          </Flexbox>
        </Box>
      )
    }
  ];

  const onRenameClick = tagId => {
    console.log('clicked rename');
    console.log(tagId)
  }

  const onArchiveClick = tagId => {
    console.log('clicked archive');
    console.log(tagId)
  }

  return (
    <Container>
      <Table columns={columns} dataSource={tags} rowKey="objectID" />
    </Container>
  );
};

const Container = styled.div`
  table:not(.table-bordered)>tbody>tr>td {
    padding: 0.125em 1em;
    border-bottom: 1px solid ${colours.lightGrey600};
  }
  table:not(.table-bordered)>thead>tr>th {
    padding: 0.5em 1em;
    background: transparent;
    border-bottom: 2px solid ${colours.darkGrey100};
    color: ${colours.navy800};
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
  }
  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: transparent;
  }
  .ant-table-tbody > tr:nth-child(odd) {
    background: ${colours.lightGrey100};
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: transparent;
  }
  .ant-table-pagination.ant-pagination {
    float: left;
  }
`;

const ActionLink = styled.span`
  color: ${colours.navy900};
  font-size: 14px;
  font-weight: 600;
  line-height: 37px;
  cursor: pointer;

  ${props => props.red && css`
    color: ${colours.red700};
  `}

  ${props => props.blue && css`
    color: ${colours.blue700};
  `}
`;
