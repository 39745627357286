import React from 'react';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { colours, defaultTheme } from '@a-cloud-guru/rainbow-ui';
import { Button, Divider, Input, Tooltip } from 'antd';
import { Spinner } from 'components/spinner';

export const StyledSpinner = styled(Spinner)`
  display: inline-block;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const MDContainer = styled.div`
  margin-bottom: 1em;
`;

export const VisibilityDraftBox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.blue100}>
    <VisibilityDraft />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is a draft.</VisibilityTitle>
      <VisibilityDesc>
        You’ll need to submit it for review in order to add it to a course.
      </VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilitySubmittedQABox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.orange100}>
    <VisibilitySubmittedQA />
    <VisibilityInfo>
      <VisibilityTitle>This {type} has been submitted for QA.</VisibilityTitle>
      <VisibilityDesc>
        You won’t be able to make any changes unless it’s returned to you for
        editing.
      </VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilityInQABox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.red100}>
    <VisibilityInQA />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is ready for QA.</VisibilityTitle>
      <VisibilityDesc>
        If it needs changes, you can either make them here or return the
        question to the author.
      </VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilityApprovedBox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.green100}>
    <VisibilityApproved />
    <VisibilityInfo>
      <VisibilityTitle>This {type} has been approved.</VisibilityTitle>
      <VisibilityDesc>
        Once the assessment type is live, it will be visible to students.
      </VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilityLiveBox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.green100}>
    <VisibilityApproved />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is live.</VisibilityTitle>
      <VisibilityDesc>
        It’ll be shown to students in the relevant assessments.
      </VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilityHiddenBox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.lightGrey300}>
    <VisibilityHidden />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is hidden.</VisibilityTitle>
      <VisibilityDesc>
        It will not be shown to students in the relevant assessments.
      </VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilityDecommissionedBox = ({ type = 'question' }) => (
  <VisibilityContainer bgColor={colours.lightGrey300}>
    <VisibilityDecommissioned />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is decommissioned.</VisibilityTitle>
      <VisibilityDesc>It has been archived.</VisibilityDesc>
    </VisibilityInfo>
  </VisibilityContainer>
);

export const VisibilityContainer = styled.div.attrs(props => ({
  bgColor: props.bgColor || '#ffffff'
}))`
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.bgColor};
  border-radius: 3px;
  box-shadow: 0 3px 15px 0 rgba(7, 1, 82, 0.06);
`;

export const VisibilityDraft = styled.div`
  background-color: ${colours.blue700};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilitySubmittedQA = styled.div`
  background-color: ${colours.orange700};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityInQA = styled.div`
  background-color: ${colours.red600};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityApproved = styled.div`
  background-color: ${colours.green900};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityHidden = styled.div`
  background-color: ${colours.darkGrey50};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityDecommissioned = styled.div`
  background-color: ${colours.darkGrey50};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityInfo = styled.div`
  padding: 1em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const VisibilityTitle = styled.div`
  margin-top: -3px;
  color: ${colours.darkGrey900};
  font-size: 0.875em;
  font-weight: 600;
`;

export const VisibilityDesc = styled.div`
  color: ${colours.darkGrey700};
  font-size: 0.8125em;
  font-weight: 400;
`;

export const NeedsEditing = () => (
  <StatusContainer bgColor={colours.orange100} color={colours.orange700}>
    Needs Editing
  </StatusContainer>
);

export const Incomplete = () => (
  <StatusContainer bgColor={colours.orange100} color={colours.orange700}>
    Incomplete
  </StatusContainer>
);

export const QA = () => (
  <StatusContainer bgColor={colours.red100} color={colours.red700}>
    Ready for QA
  </StatusContainer>
);

export const FlaggedForQA = () => (
  <StatusContainer bgColor={colours.red100} color={colours.red700}>
    Flagged for QA
  </StatusContainer>
);

export const Live = () => (
  <StatusContainer bgColor={colours.green100} color={colours.green700}>
    Live
  </StatusContainer>
);

export const Approved = () => (
  <StatusContainer bgColor={colours.green100} color={colours.green700}>
    Approved
  </StatusContainer>
);

export const Draft = () => (
  <StatusContainer bgColor={colours.blue100} color={colours.blue700}>
    Draft
  </StatusContainer>
);

export const Decommissioned = () => (
  <StatusContainer bgColor={colours.lightGrey300} color={colours.darkGrey50}>
    Decommissioned
  </StatusContainer>
);

export const Hidden = () => (
  <StatusContainer bgColor={colours.lightGrey300} color={colours.darkGrey50}>
    Hidden
  </StatusContainer>
);

export const StatusContainer = styled.div.attrs(props => ({
  bgColor: props.bgColor || '#ffffff',
  color: props.color || '#000000'
}))`
  display: inline-block;
  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 0.9285em;
  font-weight: 400;
  margin-right: 0.5em;
`;

export const FormErrorMsg = ({ message }) => (
  <ErrorMsg>
    <StyledI className="fas fa-exclamation-circle" />
    {message}
  </ErrorMsg>
);

export const ErrorContainer = styled.div`
  margin-top: -1.125em;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export const ErrorMsg = styled.div`
  padding: .2em 0;
  color: ${colours.red700};
  font-size: 14px;
  font-weight: 500;
`;

export const StyledI = styled.i`
  margin-right: 0.25em;
`;

export const OrangeLinkBox = styled.div`
  background-color: ${colours.orange700};
  border-radius: 3px;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;

  &:hover {
    background-color: ${colours.orange700};
  }
`;

export const StyledLink = styled(Link)`
  color: #ffffff;
  font-size: 1.125em;
  font-weight: 700;

  &:focus,
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: #ffffff;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderTitle = styled.h1`
  font-family: ${defaultTheme.fonts.brand};  
  color: ${colours.navy};
  font-size: 1.625em;
  font-weight: 800;
  letter-spacing: -.033em;
`;

export const HeaderDesc = styled.div`
  color: #030216;
  font-size: 0.85em;
  font-weight: 400;
`;

export const HeaderAction = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 0.5em;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 1em;
  margin-bottom: 1em;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
  padding: 1em;
`;

export const WhiteBtn = styled(Button)`
  border-radius: 3px;
  font-size: 1em;
  font-weight: 700;
  border: 0;
  color: ${colours.darkGrey900};
  background-color: #ffffff;
  box-shadow: none;
  margin-left: 0.25em;
  margin-right: 0.25em;

  &:hover,
  &:active,
  &:focus {
    color: ${colours.darkGrey900};
  }
`;

export const GreenBtn = styled(Button)`
  background-color: ${colours.green700};
  border-radius: 3px;
  font-size: 1em;
  font-weight: 700;
  border: 0;
  color: #ffffff;
  margin-left: 0.25em;
  margin-right: 0.25em;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.green700};
    color: #ffffff;
  }
`;

export const GreyBtn = styled(Button)`
  background-color: ${colours.lightGrey300};
  border-radius: 3px;
  color: ${colours.darkGrey900};
  font-size: 1em;
  font-weight: 700;
  border: 0;
  margin-left: 0.25em;
  margin-right: 0.25em;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.lightGrey500};
    color: ${colours.darkGrey900};
  }

  &:disabled {
    opacity: 0.75;
    pointer-events: none;
  }
`;

export const OrangeBtn = styled(Button)`
  background-color: ${colours.orange700};
  border-radius: 3px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  border: 0;
  margin-left: 0.25em;
  margin-right: 0.25em;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.orange700};
    color: #ffffff;
  }
`;

export const RedBtn = styled(Button)`
  background-color: ${colours.red700};
  border-radius: 3px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  border: 0;
  margin-left: 0.25em;
  margin-right: 0.25em;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.red1000};
    color: #ffffff;
  }
`;

export const DeleteIconBtn = props => (
  <Tooltip title="Delete">
    <GreyIconBtn onClick={props.onClick} data-testid="delete-answer-button">
      <StyledI className="fas fa-trash-alt" />
    </GreyIconBtn>
  </Tooltip>
);

export const TickIconBtn = props => (
  <Tooltip title={`Mark as ${!props.correct}`}>
    {props.correct ? (
      <GreenIconBtn onClick={props.onClick} data-testid="mark-as-incorrect">
        <StyledI className="fas fa-check" />
      </GreenIconBtn>
    ) : (
        <GreyIconBtn onClick={props.onClick} data-testid="mark-as-correct">
          <StyledI className="fas fa-check" />
        </GreyIconBtn>
      )}
  </Tooltip>
);

export const GreyIconBtn = styled(Button)`
  border: 0;
  padding: 0;
  color: ${colours.darkGrey900};
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${colours.darkGrey900};
    background-color: transparent;
  }
`;

export const GreenIconBtn = styled(Button)`
  border: 0;
  padding: 0;
  color: ${colours.green700};
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${colours.green700};
    background-color: transparent;
  }
`;

export const SectionLabel = styled.div`
font-family: ${defaultTheme.fonts.brand};
color: ${colours.navy500};
font-size: 1.57em;
font-weight: 800;
letter-spacing: -0.02em;
  margin: ${props => props.margin || '0'};
`;

export const SectionLabelLight = styled.h3`
  font-family: ${defaultTheme.fonts.brand};
  color: ${colours.navy500};
  font-size: 1.375em;
  font-weight: 800;
  letter-spacing: -0.02em;
  margin: ${props => props.margin || '0'};
`;

export const ItemLabel = styled.div`
  color: ${colours.navy700};
  font-size: 1.125em;
  font-weight: 600;
  margin: 1em 0 0.5em;
`;

export const LabelDesc = styled.div`
  margin-bottom: 1em;
  color: ${colours.darkGrey400};
  font-size: 0.875em;
  font-weight: 400;
`;

export const ModalTitle = styled.div`
  color: ${colours.navy800};
  font-size: 2em;
  font-weight: 700;
`;

export const ActionColumn = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 1em;
  }
`;

export const ActionCorner = styled.div`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-right: 1em;
  border: 0;
`;

export const InputMR1 = styled(Input)`
  margin-right: 1em;
  height: 42px;
  box-shadow: inset 0 1px 4px 0 ${rgba('#BFC6D8', 0.25)};
`;

export const Text14px400Navy900 = styled.div`
  color: ${colours.navy900};
  font-size: 0.875;
  font-weight: 400;
`;

export const ExplanationLinksContainer = styled.div`
  display: flex;
  position: relative;
  background-color: ${colours.lightGrey200};
  border-radius: 3px;
  margin-bottom: 0.5em;
`;

export const DisciplineContainer = styled.div`
  position: relative;
  background-color: ${colours.lightGrey200};
  border-radius: 3px;
  margin-bottom: 1.25em;
  padding: 0.5em 1em 0.5em 1em;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const ExplanationLinksBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1em 0.5em 1em;

  .ant-form-item {
    margin-bottom: 0;
  }
`;
