import React from "react";
import styled from "styled-components";
import { colours, Button, Icon, Modal } from "@a-cloud-guru/rainbow-ui";

export const AddTagBtn = styled(Button)`
  margin-left: 0.75em;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 1em;
  }
`;

export const ModalTitle = styled.div`
  color: ${colours.navy800};
  font-size: 2em;
  font-weight: 700;
`;

export const ModalDesc = styled.div`
  color: ${colours.navy800};
  font-size: 1em;
  font-weight: 400;
  text-align: left;
`;

export const ModalContent = styled.div`
  margin-top: 1em;
`;

export const ErrorContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 3px 15px 0 rgba(7, 1, 82, 0.06);
`;

export const RedVertical = styled.div`
  background-color: ${colours.red700};
  color: ${colours.red700};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const ErrorInfo = styled.div`
  background-color: ${colours.red50};
  padding: 1em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const ErrorIcon = styled(Icon)`
  background-color: ${colours.red50};
  color: ${colours.red700};
  font-size: 1.5em;
  padding: 0.5em 0 0 0.5em;
`;

export const ErrorTitle = styled.div`
  margin-top: -3px;
  color: ${colours.darkGrey900};
  font-size: 1em;
  font-weight: 600;
`;

export const ErrorMsg = styled.div`
  color: ${colours.darkGrey900};
  font-size: 0.8125em;
  font-weight: 400;
`;

export const ErrorMsgContainer = ({ title, message }) => (
  <ErrorContainer>
    <RedVertical />
    <ErrorIcon type="exclamation-circle" theme="filled" />
    <ErrorInfo>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorMsg>{message}</ErrorMsg>
    </ErrorInfo>
  </ErrorContainer>
);
