import React from 'react';
import { Form, Input } from 'antd';
import { cloneDeep } from 'lodash';
import { urlFormatValidator } from 'lib/validate-url';
import * as Styles from 'components/questions-styles';

const ExplanationLinksSection = props => {
  const { linksList, onLinksChange, getFieldDecorator } = props;

  const remove = index => {
    const newLinks = cloneDeep(linksList);
    newLinks.splice(index, 1);
    onLinksChange(newLinks);
  };

  const add = () => {
    const newLinks = cloneDeep(linksList);
    newLinks.push({ text: '', url: '' });
    onLinksChange(newLinks);
  };

  const onTextChange = (event, index) => {
    const newLinks = cloneDeep(linksList);
    newLinks[index].text = event.target.value;
    onLinksChange(newLinks);
  };

  const onUrlChange = (event, index) => {
    const newLinks = cloneDeep(linksList);
    newLinks[index].url = event.target.value;
    onLinksChange(newLinks);
  };

  const showLinks = links => {
    return links.map((link, index) => {
      return (
        <Styles.ExplanationLinksContainer key={index}>
          <Styles.ExplanationLinksBox>
            <Styles.ItemLabel>Display text</Styles.ItemLabel>
            <Form.Item>
              {getFieldDecorator(`${index}-text`, {
                initialValue: link.text,
                rules: [
                  {
                    required: true,
                    message: <Styles.FormErrorMsg message="Text is required" />
                  }
                ]
              })(
                <Input
                  placeholder={`Link #${index + 1}`}
                  onChange={event => onTextChange(event, index)}
                />
              )}
            </Form.Item>
            <Styles.ItemLabel>URL</Styles.ItemLabel>
            <Form.Item>
              {getFieldDecorator(`${index}-url`, {
                initialValue: link.url,
                rules: [
                  {
                    required: true,
                    message: <Styles.FormErrorMsg message="URL is required" />
                  },
                  {
                    pattern: urlFormatValidator,
                    message: <Styles.FormErrorMsg message="URL is invalid" />
                  }
                ]
              })(
                <Input
                  placeholder="https://example.com"
                  onChange={event => onUrlChange(event, index)}
                />
              )}
            </Form.Item>
          </Styles.ExplanationLinksBox>
          <Styles.ActionCorner>
            <Styles.DeleteIconBtn onClick={() => remove(index)} />
          </Styles.ActionCorner>
        </Styles.ExplanationLinksContainer>
      );
    });
  };

  return (
    <>
      {showLinks(linksList)}
      <Form.Item>
        <Styles.GreyBtn onClick={add}>
          <Styles.StyledI className="fa fa-plus" /> New Link
        </Styles.GreyBtn>
      </Form.Item>
    </>
  );
};

export { ExplanationLinksSection };
