import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { isEmpty, uniq, get, keyBy } from 'lodash';
import { QUESTION_STATUS } from './constants';
import { dateFromNow } from 'lib/formatDate';
import { HTML } from 'lib/html';
import * as Styles from 'components/questions-styles';
import { colours, Timeline } from '@a-cloud-guru/rainbow-ui';

const GET_USERS = gql`
  query GetUsers($input: ProfilesByIdsInput!) {
    profileByIds(input: $input) {
      id
      displayname
    }
  }
`;

const History = props => {
  const { history = [], apolloClient } = props;
  const [authorMap, setAuthorMap] = useState(null);
  const getUsers = useCallback( () => {
    return apolloClient
      .query({
        query: GET_USERS,
        variables: {
          input: { ids: uniq(history.map(item => item.authorId)) }
        }
      })
      .then(({ data }) => {
        const authors = get(data, 'profileByIds', []);
        return keyBy(authors, author => author.id);
      })
      .catch(error => {
        console.log('profileByIds error:', error);
        return {};
      });
  }, [apolloClient, history]);

  useEffect(() => {
    let isSubscribed = true;
    getUsers(apolloClient).then(authorMap => {
      if (isSubscribed) {
        setAuthorMap(authorMap);
      }
    });
    return () => isSubscribed = false
  }, [apolloClient, getUsers, history]);

  const generateTimelineItems = () => {
    if (isEmpty(history)) {
      return null;
    }

    return history.map((item, index) => {
      const { editorNote } = item;
      return (
        <Timeline.Item key={index} dot={<Dot />}>
          <Row>
            <div>
              <Label>
                {generateLabel(item.status)}
              </Label>
            </div>
            <div>
              <Date>
                {dateFromNow(item.date)}
              </Date>
            </div>
          </Row>
          <Description>
            {authorMap !== null && (get(authorMap, `[${item.authorId}].displayname`, null) || get(item, 'authorId', null))}
          </Description>
          {!isEmpty(editorNote) && <Note>{HTML({ html: editorNote })}</Note>}
        </Timeline.Item>
      );
    }
    );
  };

  const generateLabel = status => {
    if (status.toUpperCase() === QUESTION_STATUS.DRAFT) {
      return <Styles.Draft />;
    }

    if (status.toUpperCase() === QUESTION_STATUS.LIVE) {
      return <Styles.Live />;
    }

    if (status.toUpperCase() === QUESTION_STATUS.QA) {
      return <Styles.QA />;
    }

    if (status.toUpperCase() === QUESTION_STATUS.HIDDEN) {
      return <Styles.Hidden />;
    }

    if (status.toUpperCase() === QUESTION_STATUS.DECOMMISSIONED) {
      return <Styles.Decommissioned />;
    }
  };

  return (
    <Container>
      <Timeline>
        {generateTimelineItems()}
      </Timeline>
    </Container>
  )
};

const Container = styled.div`
  margin: 1em 0 0;
  padding: 1em 0.25em 0 0.25em;

  .ant-timeline-item-head-custom {
    padding: 0;
    line-height: 12px;
  }

  .ant-timeline-item-last > .ant-timeline-item-tail {
    display: block;
  }

  .ant-timeline-item.ant-timeline-item-last {
    padding-bottom: 0;
  }

  .ant-timeline-item-tail {
    border-left: 2px solid ${colours.lightGrey500};
  }
`;

const Description = styled.div`
  color: ${colours.navy};
  font-weight: 700;
  line-height: 17px;
`;

const Note = styled.div`
  color: ${colours.darkGrey400};
  font-weight: 400;

  p:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  margin-bottom: 0.5em;
`;

const Date = styled.div`
  color: ${colours.darkGrey200};
  font-weight: 400;
  line-height: 17px;
`;

const Dot = styled.div`
  display: inline-block;
  background-color: ${colours.lightGrey900};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export { History }