import React from 'react';
import styled from 'styled-components';
import { Page } from 'components/page/Page';
import { TagCategoryList } from 'components/tag/TagCategoryList';
import { colours } from '@a-cloud-guru/rainbow-ui';

export const Tags = props => {

  return (
    <Page 
      title="Tag Categories" 
      breadcrumbs={[{ text: 'Reference Data', link: '#' }, { text: 'Tags' }]}
      description={<Description>Adding entirely new tag categories requires significant development time. If you would like to add one, please contact the student team on Slack.</Description>}
    >
      <TagCategoryList />
    </Page>
  );
};

const Description = styled.p`
  color: ${colours.darkGrey900};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 10px 0 0;
`;