import React, { useCallback, useState, useEffect } from 'react';
import gql from 'graphql-tag';
import omitDeep from 'omit-deep';
import { get, isEmpty } from 'lodash';
import { getFeature } from 'lib/split';
import Form from 'react-jsonschema-form';
import styled from 'styled-components';
import { Loading } from 'components/loading/Loading';
import { Page } from 'components/page/Page';
import { rgba } from 'polished';
import { colours, Icon, Button } from '@a-cloud-guru/rainbow-ui';
import './index.less';

const schema = {
  type: 'array',
  title: '',
  contains: { type: 'object' },
  items: {
    title: '',
    type: 'object',
    required: ['id', 'text', 'goals'],
    properties: {
      id: { type: 'string', title: 'Role ID' },
      text: { type: 'string', title: 'Role Name', description: 'I am a:' },
      goals: {
        type: 'array',
        title: 'Goals',
        contains: { type: 'object' },
        items: {
          type: 'object',
          required: ['id', 'text', 'recommendations'],
          properties: {
            id: { type: 'string', title: 'Goal ID' },
            text: {
              type: 'string',
              title: 'Goal Name',
              description: 'I am looking to:'
            },
            recommendations: {
              type: 'array',
              title: 'Recommendations',
              description: 'Recommended for me:',
              contains: { type: 'object' },
              items: {
                type: 'object',
                required: ['id', 'type'],
                properties: {
                  id: { type: 'string', title: 'Course ID' },
                  type: { type: 'string', title: 'Type', default: 'course' }
                }
              }
            }
          }
        }
      }
    }
  }
};

const uiSchema = {
  classNames: 'roles-goals__form'
};

const FIELDS = `
  id
  text
  goals {
    id
    text
    recommendations {
      id
      type
    }
  }
`;

const GET_ROLES_GOALS = gql`
  query rolesAndGoals {
    rolesAndGoals {
      ${FIELDS}
    }
  }
`;

const PUT_ROLES_GOALS = gql`
  mutation ACG_putRolesAndGoals($rolesAndGoals: [RecommendationRoleInput!]) {
    ACG_putRolesAndGoals(rolesAndGoals: $rolesAndGoals) {
      ${FIELDS}
    }
  }
`;

const RolesAndGoals = props => {
  const [splitFlag, setSplitFlag] = useState('off');
  const [rolesGoals, setRolesGoals] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(false);

  const getRolesGoals = useCallback(() => {
    return props.client
      .query({
        query: GET_ROLES_GOALS,
        fetchPolicy: 'no-cache'
      })
      .then(({ data }) => get(data, 'rolesAndGoals', []))
      .catch(() => []);
  }, [ props ]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getFeature('Content_Editor_Recommendation').then(setSplitFlag);

      if (isEmpty(rolesGoals)) {
        getRolesGoals().then(setRolesGoals);
      }
    }

    return () => (isSubscribed = false);
  }, [rolesGoals, getRolesGoals]);

  const saveRolesGoals = data => {
    setAlert(false);
    const rolesAndGoals = omitDeep(data, ['__typename']);
    return props.client
      .mutate({
        mutation: PUT_ROLES_GOALS,
        variables: {
          rolesAndGoals
        }
      })
      .then(({ data }) => {
        setAlert('success');
        setSubmitting(false);
        return get(data, 'ACG_putRolesAndGoals', []);
      })
      .catch(() => {
        setAlert('error');
        setSubmitting(false);
        return [];
      });
  };

  const onSubmit = ({ formData }) => {
    setSubmitting(true);
    saveRolesGoals(formData).then(setRolesGoals);
  };

  const displayRolesGoals = () => {
    if (isEmpty(rolesGoals)) {
      return <Loading />;
    }

    return (
      <FormContainer>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={rolesGoals}
          onSubmit={onSubmit}
        >
          <button
            type="submit"
            className={`btn btn-info ${splitFlag === 'off' && 'hide-button'}`}
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </button>
        </Form>
      </FormContainer>
    );
  };

  return (
    <Page
      title="Roles & Goals Editor"
      breadcrumbs={[{ text: 'Roles & Goals' }]}
    >
      <Container>
        <ButtonContainer>
          <Button
            href="https://www.notion.so/acloudguru/Roles-Goals-Editor-Linked-via-Content-Admin-327739b57cf448748092fd80fbdb65d4"
            target="_blank"
          >
            <Icon type="read" />
            Read Documentation
          </Button>
        </ButtonContainer>
        <div>
          {displayRolesGoals()}
          <AlertContainer>
            {alert === 'success' && (
              <div className="alert alert-success" role="alert">
                Successfully submitted.
              </div>
            )}
            {alert === 'error' && (
              <div className="alert alert-danger" role="alert">
                An error occurred.
              </div>
            )}
          </AlertContainer>
        </div>
      </Container>
    </Page>
  );
};

const Container = styled.div`
  position: relative;
  padding-bottom: 5em;
  background: #fff;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 2em;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
`;

const FormContainer = styled.div`
  .hide-button {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 1.5em);
  right: 0;
`;

const AlertContainer = styled.div`
  .alert {
    margin-top: 1em;
  }
`;

export { RolesAndGoals };
