import React from 'react';
import styled from 'styled-components';
import { colours, Icon } from '@a-cloud-guru/rainbow-ui';
import { Link } from 'react-router-dom';

export const TagCategoryRow = ({ category }) => {
  return (
    <OuterContainer>
      <Link to={`/tags/${category.id}`}>
        <Container>
          <Name>{category.name}</Name>
          <Description>{category.description}</Description>
          <IconContainer>
            <Icon type="right" />
          </IconContainer>
        </Container>
      </Link>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  a {
    text-decoration: none;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 20px 0;
`;

const Name = styled.span`
  color: ${colours.navy900};
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`;

const Description = styled.p`
  color: ${colours.darkGrey900};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 4px 0 0;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  color: ${colours.navy900};
`;