import React, { useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import Form from 'react-jsonschema-form';
import styled from 'styled-components';
import { Loading } from 'components/loading/Loading';
import { Page } from 'components/page/Page';
import { rgba } from 'polished';
import { colours, Icon, Button } from '@a-cloud-guru/rainbow-ui';
import 'routes/LearningPaths/LearningPaths.less';

const schema = {
  title: '',
  type: 'object',
  required: ['id', 'name', 'description', 'shortDescription', 'bannerUrl', 'tileUrl'],
  properties: {
    id: { type: 'string', title: 'Id' },
    name: { type: 'string', title: 'Name' },
    description: { type: 'string', title: 'Description' },
    shortDescription: { type: 'string', title: 'Short Description' },
    bannerUrl: { type: 'string', title: 'Banner Url' },
    tileUrl: { type: 'string', title: 'Tile Url' },
    awards: {
      type: 'array',
      title: 'Awards',
      contains: { type: 'object' },
      items: {
        type: 'object',
        required: ['id', 'title'],
        properties: {
          id: { type: 'string', title: 'Id' },
          title: { type: 'string', title: 'Title' }
        }
      }
    },
    // Sections
    sections: {
      type: 'array',
      title: 'Sections',
      contains: { type: 'object' },
      items: {
        type: 'object',
        required: ['id', 'name'],
        properties: {
          id: { type: 'string', title: 'Section id' },
          name: { type: 'string', title: 'Name' },
          description: { type: 'string', title: 'Description (Optional)' },
          shortDescription: { type: 'string', title: 'Short Description (Optional)' },
          components: {
            type: 'object',
            title: '',
            properties: {
              // Primary components
              primary: {
                type: 'array',
                title: 'Primary Components',
                contains: { type: 'object' },
                items: {
                  type: 'object',
                  required: ['type', 'id'],
                  properties: {
                    type: { type: 'string', title: 'Primary Component Type', enum: ['COURSE', 'WEB_SERIES_EPISODE'] },
                    id: { type: 'string', title: 'Primary Component id' },
                    // Supplementary Components
                    supplementary: {
                      type: 'array',
                      title: 'Supplementary Components',
                      items: {
                        type: 'object',
                        required: ['type', 'id'],
                        properties: {
                          type: {
                            type: 'string',
                            title: 'Supplementary Component Type',
                            enum: ['COURSE', 'WEB_SERIES_EPISODE']
                          },
                          id: { type: 'string', title: 'Supplementary id' }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

const uiSchema = {
  classNames: 'learning-path__form',
  id: {
    'ui:disabled': true
  },
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 5
    }
  },
  sections: {
    classNames: 'section__component',
    items: {
      id: {
        'ui:help': 'Must be a unique guid. https://www.guidgenerator.com/'
      },
      description: {
        'ui:widget': 'textarea',
        'ui:options': {
          rows: 5
        }
      },
      components: {
        primary: {
          classNames: 'primary__component',
          items: {
            type: {
              'ui:placeholder': 'Choose an option'
            },
            id: {
              'ui:help': 'Course id or Series episode id'
            },
            supplementary: {
              classNames: 'supplementary__component',
              items: {
                type: {
                  'ui:placeholder': 'Choose an option'
                },
                id: {
                  'ui:help': 'Course id or Series episode id'
                }
              }
            }
          }
        }
      }
    }
  }
};

const GET_LEARNING_PATH = gql`
  query($id: String!) {
    learningPath(id: $id) {
      name
      id
      description
      shortDescription
      bannerUrl
      tileUrl
      started
      awards {
        id
        title
      }
      sections {
        name
        id
        description
        shortDescription
        duration
        numberOfLessons
        completed
        completedPercent
        components {
          primary {
            type
            title
            id
            url
            duration
            numberOfLessons
            completed
            completedPercent
            supplementary {
              type
              completed
              title
              id
              url
            }
          }
        }
      }
    }
  }
`;

const CREATE_LEARNING_PATH = gql`
  mutation createLearningPath($id: String!, $path: String!) {
    createLearningPath(id: $id, path: $path) {
      name
      id
      description
      shortDescription
      bannerUrl
      tileUrl
      started
      awards {
        id
        title
      }
      sections {
        name
        id
        description
        shortDescription
        duration
        numberOfLessons
        completed
        completedPercent
        components {
          primary {
            type
            title
            id
            url
            duration
            numberOfLessons
            completed
            completedPercent
            supplementary {
              type
              completed
              title
              id
              url
            }
          }
        }
      }
    }
  }
`;

const LearningPath = props => {
  const [learningPath, setLearningPath] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(false);

  const getLearningPath = useCallback(() => {
    return props.client
      .query({
        query: GET_LEARNING_PATH,
        variables: { id: get(props, 'serverState.match.params.id', null) },
        fetchPolicy: 'no-cache'
      })
      .then(({ data }) => {
        return data.learningPath || null;
      })
      .catch(() => null);
  }, [props]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && !learningPath) {
      getLearningPath().then(setLearningPath);
    }

    return () => (isSubscribed = false);
  }, [learningPath, getLearningPath]);

  const saveLearningPath = learningPath => {
    setAlert(false);
    return props.client
      .mutate({
        mutation: CREATE_LEARNING_PATH,
        variables: {
          id: learningPath.id,
          path: JSON.stringify(learningPath)
        }
      })
      .then(data => {
        setAlert('success');
        setSubmitting(false);
        return data.learningPath || null;
      })
      .catch(() => {
        setAlert('error');
        setSubmitting(false);
      });
  };

  const displayLearningPath = () => {
    if (!learningPath) {
      return <Loading />;
    }

    return (
      <Form schema={schema} uiSchema={uiSchema} formData={learningPath} onSubmit={onSubmit}>
        <button type="submit" className="btn btn-info" disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </button>
      </Form>
    );
  };

  const onSubmit = ({ formData }) => {
    setSubmitting(true);
    saveLearningPath(formData).then(setLearningPath);
  };

  const breadcrumbs = [{
    text: 'Learning Paths',
    link: '/learning-paths'
  }, {
    text: 'Edit'
  }]

  return (
    <Page title={learningPath ? `Edit '${learningPath.name}'` : 'Loading...'} breadcrumbs={breadcrumbs}>
      <Container>
        <ButtonContainer>
          <Button
            href="https://www.notion.so/acloudguru/Learning-Paths-Editor-9c767d275598426fb7aa1cac56eaa901"
            target="_blank"
          >
            <Icon type="read" />
            Read Documentation
          </Button>
        </ButtonContainer>
        <div>
          {displayLearningPath()}
          <AlertContainer>
            {alert === 'success' && (
              <div className="alert alert-success" role="alert">
                Successfully submitted.
              </div>
            )}
            {alert === 'error' && (
              <div className="alert alert-danger" role="alert">
                An error occurred.
              </div>
            )}
          </AlertContainer>
        </div>
      </Container>
    </Page>
  );
};

const Container = styled.div`
  position: relative;
  padding-bottom: 5em;
  background: #fff;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 2em;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 1.5em);
  right: 0;
`;

const AlertContainer = styled.div`
  .alert {
    margin-top: 1em;
  }
`;

export { LearningPath };
