import React from 'react';
import { Page } from 'components/page/Page';
import { QuestionForm } from './QuestionForm';

const NewQuestion = props => {
  const { client } = props;

  const defaultQuestion = {
    answers: [],
    assessmentType: [],
    explanation: {
      text: '',
      explanationLinks: []
    },
    imageUrl: '',
    questionText: '',
    disciplines: [],
    cloudProviders: [],
    technologies: [],
    services: [],
    topics: []
  };

  const breadcrumbs = [{
    link: '/questions',
    text: 'Questions'
  }, {
    text: 'Create'
  }]

  return (
    <Page title={'Create Question'} breadcrumbs={breadcrumbs}>
      <QuestionForm
        mode="NEW"
        initialQuestion={{ editorNote: 'Created', question: defaultQuestion }}
        apolloClient={client}
      />
    </Page>
  );
};

export { NewQuestion };
