import React from 'react';
import { isEmpty } from 'lodash';
import { Page } from 'components/page/Page';
import { Dashboard } from 'components/dashboard/Dashboard';
import ArtworkUploader from 'components/uploader/ArtworkUploader';

export const ImageUploader = props => {
  if (isEmpty(props.client)) {
    return null;
  }

  const onUpload = imageUrl => {
    console.log('imageUrl: ', imageUrl);
  };

  return (
    <Page>
      <Dashboard title="Image Uploader">
        <ArtworkUploader onUpload={onUpload} apolloClient={props.client} />
      </Dashboard>
    </Page>
  );
};
