import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { TagsSelector } from './TagsSelector';
import { colours } from '@a-cloud-guru/rainbow-ui';

const CLOUD_PROVIDERS = [
  { id: 'AWS', value: 'AWS' },
  { id: 'AZURE', value: 'AZURE' },
  { id: 'GCP', value: 'GCP' },
];

const TagsSection = props => {
  const { getFieldDecorator, questionInput, options } = props;
  const technologies = get(options, 'technologies', []);
  const services = get(options, 'services', []);
  const topics = get(options, 'topics', []);

  return (
    <Container>
      <StyledTagsSelector
        title="Cloud Provider"
        options={CLOUD_PROVIDERS}
        getFieldDecorator={getFieldDecorator}
        initialValue={questionInput.cloudProviders || []}
        fieldName="cloudProviders"
      />
      <StyledTagsSelector
        title="Technologies"
        options={technologies}
        getFieldDecorator={getFieldDecorator}
        initialValue={questionInput.technologies || []}
        fieldName="technologies"
      />
      <StyledTagsSelector
        title="Services"
        options={services}
        getFieldDecorator={getFieldDecorator}
        initialValue={questionInput.services || []}
        fieldName="services"
      />
      <StyledTagsSelector
        title="Topics"
        options={topics}
        getFieldDecorator={getFieldDecorator}
        initialValue={questionInput.topics || []}
        fieldName="topics"
      />
    </Container>
  )
};

const Container = styled.div`
  background: ${colours.lightGrey300};
`;

const StyledTagsSelector = styled(TagsSelector)`
  padding: 1.2em;

  & + & {
    border-top: 1px solid ${colours.lightGrey500};
  }
`;

export { TagsSection };