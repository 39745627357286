import React, { Component } from 'react';
import { authService } from '../services/auth';
import { Spinner } from 'components/spinner';
import { Error } from 'components/error';

const withAuthorization = (ComposedAuthComponent, allowRoles = ['ADMIN']) => {
  return class Authorized extends Component {

    state = {
      isAuthorized: false,
      loaded: false
    };

    async componentDidMount() {
      return authService.authorize({ allowRoles })
      .then(res => {
        const { isAuthenticated, isAuthorized, error } = res || {};

        if (!isAuthenticated) {
          authService.login();
          return;
        }

        if (!isAuthorized) {
          this.setState({
            isAuthorized: false,
            loaded: true
          });
          return;
        }

        if (error) {
          console.error(error);
        }

        this.setState({
          isAuthorized: true,
          loaded: true
        });
      });
    }

    render() {
      if (!this.state.isAuthorized && !this.state.loaded) {
        return (
          <div style={{ minHeight: '100vh', position: 'relative' }}>
            <Spinner style={{ position: 'absolute', left: '50%', top: '50%', margin: '-1.5rem 0 0 -1.5rem'}} />
          </div>
        )
      }

      if (!this.state.isAuthorized && this.state.loaded) {
        return (
          <div>
            <Error title="Not Authorized" errorMsg="You do not have the required role to access this portal."/>
          </div>
        )
      }

      return (<ComposedAuthComponent serverState={this.props} />);
    }
  };
};

export {
  withAuthorization
};
