import styled from 'styled-components';
import { rgba } from 'polished';
import { colours } from '@a-cloud-guru/rainbow-ui';

const Toolbar = styled.div`
    background: #FFF;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: right;
    padding: 8px 28px;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 28px ${rgba(colours.navy, 0.033)}, 0 0 12px ${rgba(colours.navy, 0.1)};

    .ant-btn {
        margin-left: 4px;
    }
`;

export { Toolbar }