import axios from 'axios';
import { urlFormatValidator } from './validate-url';

export const getSignedUrl = (apolloClient, filename, query) => {
  return apolloClient
    .query({
      query,
      variables: { filename },
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => data)
    .catch(error => console.log('getSignedUrl error: ', error));
};

export const uploadToS3 = (signedUrl, file, onUploadProgress) => {
  const config = {
    onUploadProgress
  };

  return axios
    .put(signedUrl, file, {
      headers: {
        'Content-Type': file.type
      },
      config
    })
    .then(response => {
      if (response.statusText === 'OK') {
        return parseUrl(signedUrl);
      }
    })
    .catch(error => console.error('s3 upload error: ', error));
};

export const parseUrl = (signedUrl = null) => {
  if (!signedUrl) {
    return null;
  }

  if (!urlFormatValidator.test(signedUrl)) {
    return null;
  }

  const split = signedUrl.split('?');

  const url = split[0];

  return url;
};
