import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import omitDeep from 'omit-deep';
import styled from 'styled-components';
import { colours, Row, Col, Form, Modal, message, Icon, Input, Button } from '@a-cloud-guru/rainbow-ui';
import { withRouter, Link } from 'react-router-dom';
import ArtworkUploader from 'components/uploader/ArtworkUploader';
import { QuestionStatus } from './QuestionStatus';
import { AnswersSection } from './AnswersSection';
import { AssessmentSection } from './AssessmentSection';
import { History } from './History';
import { TagsSection } from './TagsSection';
import { ExplanationLinksSection } from './ExplanationLinksSection';
import { Editor } from 'components/editor';
import { getAssessmentMetadata } from 'lib/get-questions-assessment-metadata';
import { validateQuestionForLive } from 'lib/validate-question-for-live';
import { validateQuestionForQa } from 'lib/validate-question-for-qa';
import { validateQuestionText } from 'lib/validate-question-text';
import { cloneDeep, isEmpty, isEqual, get } from 'lodash';
import {
  QUESTION_STATUS,
  ASSESSMENT_TYPES,
  GQL_EDITOR_QUESTION_PROPERTIES,
  SUBMITTING_TO_STATUS
} from './constants';
import { v4 as uuid } from 'uuid';
import * as Styles from 'components/questions-styles';
import { Toolbar } from 'components/toolbar/Toolbar';

const { TextArea } = Input;

export const CREATE_QUESTION_MUTATION = gql`
  mutation ACG_createQuestion($question: QuestionInput!) {
    ACG_createQuestion(question: $question) {
      questionId
    }
  }
`;

const UPDATE_QUESTION_MUTATION = gql`
  mutation ACG_updateQuestion($question: QuestionInput!) {
    ACG_updateQuestion(question: $question) {
      ${GQL_EDITOR_QUESTION_PROPERTIES}
    }
  }
`;

const buildQuestionFormFieldsModel = initialQuestion => {
  const question = get(initialQuestion, 'question', null);

  return omitDeep(
    {
      ...question,
      questionId: initialQuestion.questionId,
      editorNote: initialQuestion.editorNote
    },
    ['__typename']
  );
};

const UnwrappedQuestionForm = props => {
  const {
    initialQuestion,
    setInitialQuestion,
    apolloClient,
    form,
    mode
  } = props;

  const [questionInput, setQuestionInput] = useState(buildQuestionFormFieldsModel(initialQuestion));
  const [artworkModalVisible, setArtworkModalVisible] = useState(false);
  const [assessmentMetadata, setAssessmentMetadata] = useState({});
  const [submitting, setSubmitting] = useState(null);

  const [toggleFlagQAModal, setToggleFlagQAModal] = useState(false);
  const [togglingFlag, setTogglingFlag] = useState(false);
  const [reasonForQA, setReasonForQA] = useState('');

  const { getFieldDecorator, getFieldsValue } = form;

  useEffect(() => {
    if (!form && !form.setFieldsValue) {
      return
    }

    form.setFieldsValue({ questionText: questionInput.questionText });
    // adding form as a dependency to this useEffect will cause a infinite render cycle.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInput]);

  useEffect(() => {
    let isSubscribed = true;

    getAssessmentMetadata(apolloClient).then(metadata => {
      if (isSubscribed) {
        setAssessmentMetadata(metadata);
      }
    });

    return () => isSubscribed = false;

  }, [apolloClient]);

  const reset = () => {
    setInitialQuestion(null);
  }

  const onQuestionChange = questionText => {
    setQuestionInput({
      ...questionInput,
      questionText
    });
  };

  const onNoteChange = editorNote => {
    setQuestionInput({
      ...questionInput,
      editorNote
    });
  };

  const onAnswersChange = answers => {
    setQuestionInput({
      ...questionInput,
      answers
    });
  };

  const onExplanationLinksChange = links => {
    setQuestionInput({
      ...questionInput,
      explanation: {
        ...questionInput.explanation,
        explanationLinks: cloneDeep(links)
      }
    });
  };

  const onDisciplinesChange = disciplines => {
    setQuestionInput({
      ...questionInput,
      disciplines
    });
  };

  const onExplanationTextChange = text => {
    setQuestionInput({
      ...questionInput,
      explanation: {
        ...questionInput.explanation,
        text
      }
    });
  };

  const hideArtworkModal = e => {
    setArtworkModalVisible(false);
  };

  const showArtworkModal = () => {
    setArtworkModalVisible(true);
  };

  const onArtworkModalOk = () => {
    hideArtworkModal();
  };

  const setImageUrl = imageUrl => {
    setQuestionInput({
      ...questionInput,
      imageUrl: imageUrl
    });
  };

  const redirectToEdit = questionId => {
    props.history.push(`/question/edit/${questionId}`);
  };

  const sanitizeQuestion = mode => {
    const fieldValues = getFieldsValue();

    const sanitizedQuestion = {
      questionId: mode === 'NEW' ? uuid() : questionInput.questionId,
      editorNote: get(questionInput, 'editorNote', ''),
      questionText: get(questionInput, 'questionText', ''),
      answers: get(questionInput, 'answers', []),
      imageUrl: get(questionInput, 'imageUrl', ''),
      explanation: {
        text: get(questionInput.explanation, 'text', ''),
        explanationLinks: get(questionInput.explanation, 'explanationLinks', [])
      },
      assessmentType: makeAssessmentArray(),
      disciplines: get(questionInput, 'disciplines', []),
      cloudProviders: get(fieldValues, 'cloudProviders', []),
      technologies: get(fieldValues, 'technologies', []),
      services: get(fieldValues, 'services', []),
      topics: get(fieldValues, 'topics', [])
    };

    return omitDeep(sanitizedQuestion, ['__typename']);
  };

  const makeAssessmentArray = () => {
    let selectedAssessments = [];
    const fieldValues = getFieldsValue();

    if (fieldValues.assessmentTypeSkillsAssessment) {
      selectedAssessments.push(ASSESSMENT_TYPES.SKILL_ASSESSMENT);
    }

    return selectedAssessments;
  };

  const isQuestionValid = validator => {
    const result = validator(sanitizeQuestion(mode));

    return isEmpty(result);
  };

  const canMakeLive = () => {
    return (
      mode === 'EDIT' &&
      submitting === null &&
      isQuestionValid(validateQuestionForLive)
    );
  };

  const canSubmitForQa = () => {
    return (
      mode === 'EDIT' &&
      submitting === null &&
      isQuestionValid(validateQuestionForQa)
    )
  }

  const formDirty = () => {
    return !isEqual(
      sanitizeQuestion(),
      buildQuestionFormFieldsModel(initialQuestion)
    );
  };

  const isFormValid = () => {
    let result;

    form.validateFields(err => {
      result = err ? false : true;
    });

    return result;
  };

  const onCreateDraft = () => {
    if (isFormValid()) {
      setSubmitting(SUBMITTING_TO_STATUS.DRAFT);
      const sanitizedQuestion = sanitizeQuestion(mode);
      return createDraft(sanitizedQuestion)
        .then(({ data }) => {
          const createdQuestion = get(data, 'ACG_createQuestion', null);
          message.success('Question submitted.');
          redirectToEdit(createdQuestion.questionId);
        })
        .catch(error => {
          console.log('Error: ', error);
          message.error('Something went wrong.');
        });
    }
  };

  const createDraft = sanitizedQuestion => {
    return apolloClient.mutate({
      mutation: CREATE_QUESTION_MUTATION,
      variables: { question: sanitizedQuestion }
    });
  };

  const onUpdateQuestion = ({ status, successMessage }) => {
    if (isFormValid()) {
      setSubmitting(SUBMITTING_TO_STATUS[status]);
      const sanitizedQuestion = sanitizeQuestion(mode);
      return updateQuestion({ status, sanitizedQuestion })
        .then(({ data }) => {
          reset();
          const updatedQuestion = get(data, 'ACG_updateQuestion', null);
          setInitialQuestion(updatedQuestion);
          message.success(successMessage);
        })
        .catch(error => {
          console.log('Error:', error);
          message.error('Failed to updated question');
        });
    };
  };

  const updateQuestion = ({ status, sanitizedQuestion }) => {
    return apolloClient.mutate({
      mutation: UPDATE_QUESTION_MUTATION,
      variables: {
        question: {
          ...sanitizedQuestion,
          questionStatus: status || initialQuestion.questionStatus
        }
      }
    });
  };

  const showHistory = () => {
    const history = get(initialQuestion, 'history', []);
    if (isEmpty(history)) {
      return 'No history to display yet.';
    }

    return (
      <History history={history} apolloClient={apolloClient} />
    );
  };

  const getApproveButtonText = () => {
    if (submitting === SUBMITTING_TO_STATUS.LIVE) {
      return 'Approving';
    }

    return formDirty() ? 'Save and Approve' : 'Approve';
  };

  const showToggleFlagQaBtn = () => {
    if (mode !== 'EDIT') {
      return null;
    }

    if (initialQuestion.isFlaggedForQA) {
      return (
        <Styles.OrangeBtn disabled={togglingFlag} onClick={showToggleFlagQAModal}>
          <Styles.StyledI className="fas fa-flag" />Remove Flag
        </Styles.OrangeBtn>
      )
    }

    return (
      <Button type={'danger'} disabled={togglingFlag} onClick={showToggleFlagQAModal}>
        <Styles.StyledI className="fas fa-flag" />Flag for QA
      </Button>
    )
  };

  const showToggleFlagQAModal = () => {
    setToggleFlagQAModal(true);
  };

  const onToggleFlagQAConfirm = e => {
    e.preventDefault();
    setTogglingFlag(true);
    const { questionId, isFlaggedForQA } = initialQuestion;
    return toggleFlagForQA({ questionId, isFlaggedForQA }).then(({ data }) => {
      const updatedQuestion = get(data, 'ACG_updateQuestion', null);
      reset();
      setInitialQuestion(updatedQuestion);
      message.success('Succeeded.');
    })
      .catch(error => {
        console.log('Error:', error);
        message.error('Something went wrong');
      });
  };

  const onToggleFlagQACancel = e => {
    setToggleFlagQAModal(false);
  };

  const toggleFlagForQA = ({ questionId, isFlaggedForQA }) => {
    const flagged = !isFlaggedForQA;
    const note = flagged ?
      `Flagged for QA: ${reasonForQA}`
      :
      `Remove QA flag: ${reasonForQA}`;
    return apolloClient.mutate({
      mutation: UPDATE_QUESTION_MUTATION,
      variables: {
        question: {
          questionId: questionId,
          isFlaggedForQA: flagged,
          editorNote: note
        }
      }
    });
  }

  return (
    <Row>
      <Col span={18}>
        <Styles.FormContainer>
          <Styles.Header>
            <div>
              <Styles.HeaderDesc>
                {initialQuestion.questionId &&
                  `Question ID: ${initialQuestion.questionId}`}
              </Styles.HeaderDesc>
            </div>
            <Toolbar>
              <Link to="/questions" data-testid="cancel-button">
                <Button
                  type="outline"
                  disabled={submitting !== null}
                >
                  {mode === 'NEW' ? 'Discard' : 'Cancel'}
                </Button>
              </Link>
              {showToggleFlagQaBtn()}
              {initialQuestion.questionStatus === QUESTION_STATUS.LIVE && (
                <Button
                  type="positive"
                  disabled={!formDirty() || !canMakeLive()}
                  onClick={() => onUpdateQuestion({ status: QUESTION_STATUS.LIVE, successMessage: 'Question published.' })}
                  data-testid='save-and-publish-button'
                >
                  {submitting === SUBMITTING_TO_STATUS.LIVE ? 'Publishing' : 'Save and Publish'}
                </Button>
              )}

              {(initialQuestion.questionStatus === QUESTION_STATUS.DRAFT || mode === 'NEW') && (
                <Button
                  type="positive"
                  disabled={!formDirty() || submitting !== null || isEmpty(questionInput.questionText)}
                  onClick={mode === 'NEW' ? onCreateDraft : () => onUpdateQuestion({
                    successMessage: 'Question updated.', status: QUESTION_STATUS.DRAFT
                  })}
                  data-testid="save-button"
                >
                  <Icon type="file-text" theme="filled" />
                  {submitting === SUBMITTING_TO_STATUS.DRAFT ? 'Saving' : 'Save Draft'}
                </Button>
              )}

              {(initialQuestion.questionStatus === QUESTION_STATUS.DRAFT && mode === 'EDIT') && (
                <Button
                  type="positive"
                  disabled={formDirty() || !canSubmitForQa()}
                  onClick={() => onUpdateQuestion({
                    successMessage: 'Question submitted for QA.',
                    status: SUBMITTING_TO_STATUS.QA
                  })}
                  data-testid="submit-for-qa-button"
                >
                  {submitting === SUBMITTING_TO_STATUS.QA ? 'Submitting' : 'Submit for QA'}
                </Button>
              )}

              {initialQuestion.questionStatus === QUESTION_STATUS.QA && (
                <>
                  <Button
                    data-testid='reject-button'
                    disabled={submitting !== null}
                    onClick={() => onUpdateQuestion({
                      status: QUESTION_STATUS.DRAFT,
                      successMessage: 'Returned for editing'
                    })}
                  >
                    {submitting === SUBMITTING_TO_STATUS.DRAFT ? 'Returning' : 'Return for Edits'}
                  </Button>
                  <Button
                    type="positive"
                    disabled={!canMakeLive()}
                    onClick={() => onUpdateQuestion({
                      status: QUESTION_STATUS.LIVE,
                      successMessage: 'Question approved.'
                    })}
                    data-testid='approve-button'
                  >
                    {getApproveButtonText()}
                  </Button>
                </>
              )}
            </Toolbar>
          </Styles.Header>
          <Styles.StyledDivider />
          <Form>
            <Row gutter={16}>
              <Col span={12}>
                <Styles.SectionLabel>Question Details</Styles.SectionLabel>
                <Styles.ItemLabel>Question</Styles.ItemLabel>
                <Styles.LabelDesc />
                <Styles.MDContainer>
                  <Form.Item>
                    {getFieldDecorator('questionText', {
                      initialValue: questionInput.questionText,
                      rules: [
                        {
                          validator: validateQuestionText,
                          message: (
                            <Styles.FormErrorMsg message="Question is required" />
                          )
                        }
                      ]
                    })(
                      <Editor
                        placeholder="Type the question here."
                        onTextChange={onQuestionChange}
                        data-testid="question-text"
                      />
                    )}
                  </Form.Item>
                </Styles.MDContainer>
                <Styles.FlexContainer>
                  <Button onClick={showArtworkModal}>
                    <Styles.StyledI className="fas fa-image" /> Upload Image
              </Button>
                </Styles.FlexContainer>
                <Styles.ItemLabel>Answers</Styles.ItemLabel>
                <AnswersSection
                  answersList={questionInput.answers}
                  getFieldDecorator={getFieldDecorator}
                  onAnswersChange={onAnswersChange}
                />
                <Styles.SectionLabel>Explanation</Styles.SectionLabel>
                <Styles.LabelDesc>
                  Explanations are only shown in course quizzes and exam simulators.
            </Styles.LabelDesc>
                <Styles.ItemLabel>Explanation</Styles.ItemLabel>
                <Styles.LabelDesc />
                <Form.Item>
                  {getFieldDecorator('explanation', {
                    initialValue:
                      (questionInput.explanation &&
                        questionInput.explanation.text &&
                        questionInput.explanation.text) ||
                      ''
                  })(
                    <Editor
                      placeholder="This will be shown after the user has answered the question, if the assessment type supports it."
                      onTextChange={onExplanationTextChange}
                    />
                  )}
                </Form.Item>
                <Styles.ItemLabel>Explanation</Styles.ItemLabel>
                <Styles.LabelDesc />
                <ExplanationLinksSection
                  linksList={
                    (questionInput.explanation &&
                      questionInput.explanation.explanationLinks &&
                      questionInput.explanation.explanationLinks) ||
                    []
                  }
                  getFieldDecorator={getFieldDecorator}
                  onLinksChange={onExplanationLinksChange}
                />
              </Col>
              <Col span={12}>
                <Styles.ItemLabel>Notes (optional)</Styles.ItemLabel>
                <Styles.LabelDesc>
                  This won’t be seen by any students.
            </Styles.LabelDesc>
                <Styles.MDContainer>
                  <Form.Item>
                    {getFieldDecorator('editorNote', {
                      initialValue: questionInput.editorNote
                    })(
                      <Editor
                        placeholder="Leave context, notes, or things to remember in here."
                        onTextChange={onNoteChange}
                      />
                    )}
                  </Form.Item>
                </Styles.MDContainer>
              </Col>
            </Row>
            <Styles.StyledDivider margin="2.85em 0" />
            <Row>
              <Col span={12}>
                <AssessmentSection
                  options={assessmentMetadata}
                  questionInput={questionInput}
                  getFieldDecorator={getFieldDecorator}
                  onDisciplinesChange={onDisciplinesChange}
                />
              </Col>
              <Col span={12}>
                <TagsSection
                  options={assessmentMetadata}
                  questionInput={questionInput}
                  getFieldDecorator={getFieldDecorator}
                />
              </Col>
            </Row>
          </Form>
          <Modal
            title={<Styles.ModalTitle>Upload Image</Styles.ModalTitle>}
            destroyOnClose={true}
            okButtonProps={{ disabled: isEmpty(questionInput.imageUrl) }}
            visible={artworkModalVisible}
            onOk={onArtworkModalOk}
            onCancel={hideArtworkModal}
            footer={[
              <Button key="back" onClick={hideArtworkModal}>
                Cancel
          </Button>,
              <Button
                type="positive"
                key="confirm"
                onClick={onArtworkModalOk}
                disabled={isEmpty(questionInput.imageUrl)}
              >
                Confirm
          </Button>
            ]}
          >
            <ArtworkUploader onUpload={setImageUrl} apolloClient={apolloClient} />
          </Modal>
          <Modal
            title={<Styles.ModalTitle>{initialQuestion.isFlaggedForQA ? 'Remove Flag' : 'Flag for QA'}</Styles.ModalTitle>}
            okText="Submit"
            visible={toggleFlagQAModal}
            onOk={onToggleFlagQAConfirm}
            onCancel={onToggleFlagQACancel}
            footer={[
              <Button key="back" onClick={onToggleFlagQACancel}>
                Cancel
            </Button>,
              <Button
                type="positive"
                key="save"
                onClick={onToggleFlagQAConfirm}
                disabled={isEmpty(reasonForQA) || togglingFlag}
              >
                Submit{togglingFlag && 'ting'}
              </Button>
            ]}
          >
            <ConfirmText>
              You are about to {initialQuestion.isFlaggedForQA ? 'unflag this question' : 'flag this question for QA'}.
          </ConfirmText>
            <ConfirmLabel>Please enter a reason: </ConfirmLabel>
            <StyledTextArea
              value={reasonForQA}
              autoSize={{ minRows: 5, maxRows: 15 }}
              onChange={e => setReasonForQA(e.target.value)}
            />
          </Modal>
        </Styles.FormContainer>

      </Col>
      <Aside span={6}>
        <QuestionStatus status={initialQuestion.questionStatus} />
        {mode === 'EDIT' && (
          <>
            <Styles.SectionLabelLight>
              Question History
                </Styles.SectionLabelLight>
            <Styles.LabelDesc>{showHistory()}</Styles.LabelDesc>
          </>
        )}

      </Aside>
    </Row>
  );
};

const Aside = styled(Col)`
  background: ${colours.lightGrey200};
  padding-left: 20px;
`;

const QuestionForm = withRouter(
  Form.create({ name: 'questionForm' })(UnwrappedQuestionForm)
);

const ConfirmLabel = styled.div`
  color: ${colours.navy700};
  font-size: 1em;
  font-weight: 600;
`;

const ConfirmText = styled.div`
  color: ${colours.navy900};
  font-size: 1em;
  font-weight: 500;
`;

const StyledTextArea = styled(TextArea)`
  margin-top: 1em;
`;

export { QuestionForm };
