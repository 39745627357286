import { isEmpty, orderBy } from 'lodash';

export const alphabeticallySort = params => {
  if (isEmpty(params)) {
    return [];
  }

  const sorted = orderBy(params, [param => param.value.toLowerCase()], ['asc']);
  return sorted;
};
