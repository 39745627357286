import styled, { css } from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Icon, Table } from 'antd';

export const StyledTable = styled(Table)`
  background-color: #fff;
  flex: 1;

  tr > th {
    font-weight: 600;
    color: ${colours.gray900};
    background-color: ${colours.lightGrey200};
    ${props =>
    props.title &&
    css`
        border-top: 1px solid ${colours.lightGrey300};
      `}
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: ${colours.lightGrey200};
  }

  tr > td {
    padding: 0.75em 1em;
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  tr:hover > td {
    background-color: ${colours.lightGrey500};
  }

  .ant-table-title {
    padding: 0.5em 0 0 0;
  }

  .ant-table-column-title {
    color: ${colours.navy700};
    font-size: 1em;
    font-weight: 600;
  }

  em {
    font-style: normal;
    background-color: ${colours.yellow200};
  }

  .ant-table-content {
    border-radius: 0;
  }

  .ant-pagination {
    display: flex;
    flex: 1;
    float: none;
    > * {
      order: 2;
    }
  }
  .ant-pagination-total-text {
    flex: 1;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: ${props =>
    props.overLimit ? 'space-between' : 'flex-start'};
  }
`;

export const TooManyResults = styled.span`
  color: ${colours.gray500};
  font-size: 0.875em;
  font-weight: 400;
  padding: 5px;
`;

export const TotalResults = styled.span`
  color: ${colours.gray700};
  font-size: 0.875em;
  font-weight: 400;
`;

export const TooManyResultsIcon = styled(Icon)`
  margin: 0 0.5em 0 0.75em;
  color: ${colours.blue200};
`;
