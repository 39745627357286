import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input } from 'antd';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { useDropzone } from 'react-dropzone';
import * as LabStyles from 'components/labsstyles';
import { s3AttachmentUpload } from 'lib/upload-attachment-s3';

// List of file extentions accepted by Content Service
const ALLOW_EXTENTIONS = `.jpg, .jpeg, .png, .csv, .rtf, .txt, .epub, .pdf, .doc, .docx, .ppt, .pptx, .xlsx, .odp, .ods, .odt, .json, .tar, .rar, .zip`;

const UnwrappedResourceUploader = props => {
  const [files, setFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [form, setForm] = useState({});
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [percentage, setPercentage] = useState('');

  const { apolloClient, onSetResource } = props;
  const { getFieldDecorator, setFieldsValue } = props.form;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFiles(acceptedFiles);
    setInvalidFiles(rejectedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 5368709120, // 5Gb
    accept: ALLOW_EXTENTIONS
  });

  const onUploadProgress = event => {
    const completed = Math.round((event.loaded * 100) / event.total);
    setPercentage(completed);
  };

  const onInputChange = input => {
    onSetResource({
      ...form,
      ...input
    });
    setForm({
      ...form,
      ...input
    });
  };

  const onUpload = async e => {
    setUploadingStatus(true);
    const url = await s3AttachmentUpload(apolloClient, files[0], onUploadProgress);
    onInputChange({ url });
    setFieldsValue({ url });
    setUploadingStatus(false);
  };

  const onChange = event => {
    onInputChange({ [event.target.name]: event.target.value });
  };

  const getAllowedFileExt = () => {
    return ALLOW_EXTENTIONS.replace(/,/g, '');
  };

  return (
    <>
      <LabStyles.FileContainer>
        {!isEmpty(files[0]) && <div>File: {files[0].name}</div>}
        {!isEmpty(invalidFiles) && <LabStyles.FormErrorMsg message="Invalid file extention" />}
        <Container {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <p>Drop the file here ...</p>
            </>
          ) : (
              <>
                <p>Drag 'n' drop the file here, or click to select the file</p>
              </>
            )}
        </Container>
        {!isEmpty(files[0]) && (
          <LabStyles.UploadBtn onClick={onUpload} disabled={uploadingStatus}>
            Upload{uploadingStatus && `ing: ${percentage}%`}
          </LabStyles.UploadBtn>
        )}
      </LabStyles.FileContainer>
      <LabStyles.ItemLabel>Allowed Extentions:</LabStyles.ItemLabel>
      <AllowedFiles>{getAllowedFileExt()}</AllowedFiles>
      <LabStyles.StyledDivider />
      <LabStyles.ItemLabel>Or paste an URL here: </LabStyles.ItemLabel>
      <LabStyles.StyledDivider />
      <LabStyles.ItemLabel>Title</LabStyles.ItemLabel>
      <LabStyles.LabelDesc>The name of this resource.</LabStyles.LabelDesc>
      <Form.Item>
        {getFieldDecorator('title', {
          initialValue: form.title,
          rules: [
            {
              required: true,
              message: <LabStyles.FormErrorMsg message="Title is required" />
            }
          ]
        })(<Input name="title" placeholder="Title" onChange={onChange} />)}
      </Form.Item>
      <LabStyles.ItemLabel>URL</LabStyles.ItemLabel>
      <LabStyles.LabelDesc>The URL of this resource.</LabStyles.LabelDesc>
      <Form.Item>
        {getFieldDecorator('url', {
          initialValue: form.url,
          rules: [
            {
              required: true,
              message: <LabStyles.FormErrorMsg message="URL is required" />
            },
            {
              type: 'url',
              message: <LabStyles.FormErrorMsg message="URL is invalid" />
            }
          ]
        })(<Input name="url" placeholder="URL" onChange={onChange} />)}
      </Form.Item>
    </>
  );
};

export const getColor = props => {
  if (props.isDragAccept) {
    return colours.green400;
  }
  if (props.isDragReject) {
    return colours.red700;
  }
  if (props.isDragActive) {
    return colours.blue400;
  }
  return colours.lightGrey300;
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: ${colours.lightGrey300};
  color: ${colours.darkGrey300};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const AllowedFiles = styled.pre`
  white-space:pre-wrap;
  word-break:break-word;
  text-align: center;
  margin-top: 1.5px;
`;

const ResourceUploader = Form.create({ name: 'resourceUploader' })(UnwrappedResourceUploader);

export default ResourceUploader;
