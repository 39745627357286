import { get, isEmpty, filter, every, has } from 'lodash';

export const validateQuestionForLive = question => {
  const questionText = get(question, 'questionText', null);

  if (isEmpty(questionText)) {
    return 'questionText is required';
  }

  const assessmentType = get(question, 'assessmentType', null);
  if (isEmpty(assessmentType)) {
    return 'assessmentType is required';
  }

  const cloudProviders = get(question, 'cloudProviders', null);
  if (isEmpty(cloudProviders)) {
    return 'cloudProvider is required';
  }

  const disciplines = get(question, 'disciplines', null);
  if (isEmpty(disciplines)) {
    return 'a discipline is required';
  }

  if (
    !every(disciplines, discipline => {
      return !!discipline.skillLevel;
    })
  ) {
    return 'skillLevel is required';
  }

  if (
    !every(disciplines, discipline => {
      return !!discipline.role;
    })
  ) {
    return 'role is required';
  }

  const answers = get(question, 'answers', []);

  if (isEmpty(answers)) {
    return 'At least one correct answer is required';
  }

  if (
    !every(answers, answer => {
      return !!answer.text;
    })
  ) {
    return 'text is required';
  }

  if (
    !every(answers, answer => {
      return has(answer, 'correct');
    })
  ) {
    return 'correct is required';
  }

  if (isEmpty(filter(answers, ['correct', true]))) {
    return 'At least one correct answer is required';
  }

  return null;
};
