import React, { useState, useEffect, useCallback } from 'react';
import gql from 'graphql-tag';
import omitDeep from 'omit-deep';
import { get, isEmpty } from 'lodash';
import { getFeature } from 'lib/split';
import Form from 'react-jsonschema-form';
import styled from 'styled-components';
import { Loading } from 'components/loading/Loading';
import { Page } from 'components/page/Page';
import { rgba } from 'polished';
import { colours, Icon, Button } from '@a-cloud-guru/rainbow-ui';
import './index.less';

const schema = {
  type: 'array',
  title: '',
  contains: { type: 'object' },
  items: {
    title: '',
    type: 'object',
    required: ['courseId', 'relatedCourses'],
    properties: {
      courseId: {
        type: 'string',
        title: 'Course ID',
        description:
          'The course that you want to associate next level or related courses with'
      },
      nextCourse: {
        type: 'array',
        title: 'Next Course',
        description:
          'The recommended courses after finishing the current course',
        items: {
          type: 'string',
          title: 'Course ID',
          properties: {
            courseId: { type: 'string', title: 'Course ID' }
          }
        }
      },
      relatedCourses: {
        type: 'array',
        title: 'Related Courses',
        description: 'The courses related to the current course',
        contains: { type: 'string' },
        items: {
          type: 'string',
          title: 'Course ID',
          required: ['courseId'],
          properties: {
            courseId: { type: 'string', title: 'Course ID' }
          }
        }
      }
    }
  }
};

const uiSchema = {
  classNames: 'recommender__form'
};

const FIELDS = `
  courseId
  nextCourse
  relatedCourses
`;

const GET_RELATED_COURSES = gql`
  query relatedCourses {
    relatedCourses {
      ${FIELDS}
    }
  }
`;

const PUT_RELATED_COURSES = gql`
  mutation ACG_putRelatedCourses($relatedCourses: [RelatedCourseInput!]) {
    ACG_putRelatedCourses(relatedCourses: $relatedCourses) {
      ${FIELDS}
    }
  }
`;

const CourseRecommender = props => {
  const [splitFlag, setSplitFlag] = useState('off');
  const [recommendations, setRecommendations] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(false);

  const getRecommendations = useCallback(() => {
    return props.client
      .query({
        query: GET_RELATED_COURSES,
        fetchPolicy: 'no-cache'
      })
      .then(({ data }) => get(data, 'relatedCourses', []))
      .catch(() => []);
  }, [props.client]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getFeature('Content_Editor_Recommendation').then(setSplitFlag);
    }

    return () => (isSubscribed = false);
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (isEmpty(recommendations) && isSubscribed) {
      getRecommendations().then(setRecommendations);
    }

    return () => (isSubscribed = false);
  }, [recommendations, getRecommendations]);

  const saveRecommendations = data => {
    setAlert(false);
    const relatedCourses = omitDeep(data, ['__typename']);
    return props.client
      .mutate({
        mutation: PUT_RELATED_COURSES,
        variables: {
          relatedCourses
        }
      })
      .then(({ data }) => {
        setAlert('success');
        setSubmitting(false);
        return get(data, 'ACG_putRelatedCourses', []);
      })
      .catch(() => {
        setAlert('error');
        setSubmitting(false);
        return [];
      });
  };

  const onSubmit = ({ formData }) => {
    setSubmitting(true);
    saveRecommendations(formData).then(setRecommendations);
  };

  const displayRecommendation = () => {
    if (isEmpty(recommendations)) {
      return <Loading />;
    }

    return (
      <FormContainer>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={recommendations}
          onSubmit={onSubmit}
        >
          <button
            type="submit"
            className={`btn btn-info ${splitFlag === 'off' && 'hide-button'}`}
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </button>
        </Form>
      </FormContainer>
    );
  };

  return (
    <Page
      title="Course Recommendation Editor"
      breadcrumbs={[{ text: 'Course Recommendations' }]}
    >
      <Container>
        <ButtonContainer>
          <Button
            href="https://www.notion.so/acloudguru/Course-Recommender-Linked-via-Content-Admin-71db1e842e29407ba8970a6456d589aa"
            target="_blank"
          >
            <Icon type="read" />
            Read Documentation
          </Button>
        </ButtonContainer>
        <div>
          {displayRecommendation()}
          <AlertContainer>
            {alert === 'success' && (
              <div className="alert alert-success" role="alert">
                Successfully submitted.
              </div>
            )}
            {alert === 'error' && (
              <div className="alert alert-danger" role="alert">
                An error occurred.
              </div>
            )}
          </AlertContainer>
        </div>
      </Container>
    </Page>
  );
};

const Container = styled.div`
  position: relative;
  padding-bottom: 5em;
  background: #fff;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 2em;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
`;

const FormContainer = styled.div`
  .hide-button {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: calc(100% + 1.5em);
  right: 0;
`;

const AlertContainer = styled.div`
  .alert {
    margin-top: 1em;
  }
`;

export { CourseRecommender };
