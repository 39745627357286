import React, { Component } from 'react';
import { authService } from 'services/auth';
import { Spinner } from 'components/spinner';
import { getConfig } from 'config/get-config';

const { DOMAIN } = getConfig();

class LoginCallback extends Component {
  async componentDidMount() {
    await authService.loginCallback();
    window.location.replace(DOMAIN);
  }

  render() {
    return (
      <div style={{ minHeight: '100vh', position: 'relative' }}>
        <Spinner style={{ position: 'absolute', left: '50%', top: '50%', margin: '-1.5rem 0 0 -1.5rem'}} />
      </div>
    )
  }
}

export { LoginCallback };