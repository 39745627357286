/* @jsx jsx */
import React from 'react';
// eslint-disable-next-line
import { jsx } from 'theme-ui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colours, Box, Breadcrumb, Flexbox, Icon, defaultTheme } from '@a-cloud-guru/rainbow-ui';

const Masthead = ({ title, description, breadcrumbs, actionElement, actionButtons }) => {
  return (
    <Flexbox mb="s4" flexDirection="row">
      <div sx={{ flexGrow: '1' }}>
        {breadcrumbs && generateBreadcrumbs(breadcrumbs)}
        <Title>{title}</Title>
        {React.isValidElement(description) ? description : <Description>{description}</Description>}
      </div>
      <div sx={{ display: 'flex' }}>
        {React.isValidElement(actionElement) && actionElement}
        {actionButtons && <ActionButtons list={actionButtons} />}
      </div>
    </Flexbox>
  );
};

const ActionButtons = props => {
  return props.list.map((i, index) => {
    return <a href={i.href} key={index} target="_blank" rel="noopener noreferrer" sx={{ textAlign: 'center', ml: 's4' }}>
      <Icon component={i.icon} sx={{ fontSize: '44px', display: 'block' }} />
      <span sx={{ fontSize: '12px', fontWeight: '600' }}>{i.text}</span>
    </a>
  })
};

const generateBreadcrumbs = list => {
  return (
    <BreadcrumbBox sx={{ mb: 's2' }}>
      <Breadcrumb>
        {list.map(({ text, link }, index) => {
          return (
            <Breadcrumb.Item key={index}>{link ? <BreadcrumbLinked text={text} link={link} /> : text}</Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </BreadcrumbBox>
  );
};

const BreadcrumbLinked = ({ text, link }) => {
  return <Link to={link}>{text}</Link>;
};

const BreadcrumbBox = styled(Box)`
.ant-breadcrumb,
.ant-breadcrumb a {
  height: 20px;
  line-height: 20px;
}
  .ant-breadcrumb-link,
  .ant-breadcrumb-separator {
    color: ${colours.navy300} !important;

    a {
      color: ${colours.navy200};

      &:hover {
        color: ${colours.navy300};
      }
      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
`;

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.brand};
  font-weight: 700;
  font-size: 2em;
  letter-spacing: -1px;
  line-height: 1;
  margin: 0;
  flex-grow: 1;
`;

const Description = styled.p`
  margin: 0.25em 0 0;
  font-size: 0.875em;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 1.5;
`;

export { Masthead };
