import React from 'react';
import styled from 'styled-components';
import { colours, Icon, Row, Col } from '@a-cloud-guru/rainbow-ui';
import { Link } from 'react-router-dom';
import { Page } from 'components/page/Page';
import { Card } from 'components/card/Card';

import { ReactComponent as LearningPathIcon } from 'assets/images/nav-learning-paths.svg';
import { ReactComponent as ImageUploadIcon } from 'assets/images/nav-image-upload.svg';
import { ReactComponent as QuestionEngineIcon } from 'assets/images/nav-question-engine.svg';
import { ReactComponent as ReferenceDataIcon } from 'assets/images/nav-reference-data.svg';
import { ReactComponent as TagsIcon } from 'assets/images/nav-tags.svg';

const generateMenuItems = items => {
  return items.map(item => {
    if (item.flag && item.flag !== 'on') {
      return null;
    }

    return (
      <Col span={6} key={item.path}>
        <Link to={item.path}>
          <Card cardTop={<StyledIcon component={item.icon} />} cardBottom={<>{item.title}</>} />
        </Link>
      </Col>
    );
  });
};

const Home = props => {
  const menuItems = [
    {
      path: '/questions',
      icon: QuestionEngineIcon,
      title: 'Questions'
    },
    {
      path: '/learning-paths',
      icon: LearningPathIcon,
      title: 'Learning Paths'
    },
    {
      path: '/course-recommender',
      icon: ReferenceDataIcon,
      title: 'Course Recommender'
    },
    {
      path: '/roles-goals',
      icon: ReferenceDataIcon,
      title: 'Roles And Goals'
    },
    {
      path: '/image-upload',
      icon: ImageUploadIcon,
      title: 'Image Uploader'
    },
    {
      path: '/tags',
      icon: TagsIcon,
      title: 'Tags'
    }
  ];

  return (
    <Page title="Content Editor" description="Welcome to the A Cloud Guru Content Editor">
      <Container>
        <Row gutter={30}>{generateMenuItems(menuItems)}</Row>
      </Container>
    </Page>
  );
};

const Container = styled.div`
  a:hover,
  a:focus {
    text-decoration: none;
  }

  svg * {
    fill: ${colours.navy300};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 4em;
  color: ${colours.navy300};
`;

export { Home };
