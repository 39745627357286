export const TAG_CATEGORY = {
  CLOUD_PROVIDERS: {
    id: 'cloud-providers',
    name: 'Cloud Providers',
    description: 'Such as AWS, GCP, Azure',
  },
  TECHNOLOGIES: {
    id: 'technologies',
    name: 'Technologies',
    description: 'Key technologies like Kubernetes, as well as services like AWS Lambda',
  },
  TOPICS: {
    id: 'topics',
    name: 'Topics',
    description: 'High level buckets like Serverless, Containers etc.',
  },
  ROLES: {
    id: 'roles',
    name: 'Roles',
    description: 'Who does the content apply to, Solutions Architects etc.',
  },
  SKILL_LEVELS: {
    id: 'skill-levels',
    name: 'Skill Levels',
    description: 'Who should take this course, lab?',
  }
}