export const QUESTION_STATUS = {
  DRAFT: 'DRAFT',
  QA: 'QA',
  LIVE: 'LIVE',
  HIDDEN: 'HIDDEN',
  DECOMMISSIONED: 'DECOMMISSIONED'
};

export const ASSESSMENT_TYPES = {
  SKILL_ASSESSMENT: 'SKILL_ASSESSMENT',
  EXAM_SIMULATOR: 'EXAM_SIMULATOR',
  COURSE: 'COURSE'
};

export const DEFAULTS = {
  SKILL_LEVEL: 'Novice',
  ROLE: 'AWS Architecture',
};

export const OTHER_ASSESSMENT_TYPES =
  'NOT question.assessmentType:COURSE AND NOT question.assessmentType:EXAM_SIMULATOR AND NOT question.assessmentType:SKILL_ASSESSMENT';

export const OTHER_CLOUD_PROVIDERS =
  'NOT question.cloudProviders:AWS AND NOT question.cloudProviders:AZURE AND NOT question.cloudProviders:GCP';

export const OTHER_SKILL_LEVELS =
  'NOT question.disciplines.skillLevel:Novice AND NOT question.disciplines.skillLevel:Apprentice AND NOT question.disciplines.skillLevel:Practitioner AND NOT question.disciplines.skillLevel:Professional AND NOT question.disciplines.skillLevel:Guru';

export const GQL_EDITOR_QUESTION_PROPERTIES = `
  questionId
  authorId
  editorNote
  lastUpdated
  isFlaggedForQA
  questionStatus
  question {
    answers {
      answerId
      text
      correct
    }
    questionText
    explanation {
      text
      explanationLinks {
        text
        url
      }
    }
    imageUrl
    assessmentType
    disciplines {
      skillLevel
      role
    }
    cloudProviders
    technologies
    services
    topics
  }

  questionHistory {
    version
    questionData {
      answers {
        answerId
        text
        correct
      }
      questionText
      explanation {
        text
        explanationLinks {
          text
          url
        }
      }
      imageUrl
    }
  }

  history {
    authorId
    status
    editorNote
    date
  }
`;

export const SUBMITTING_TO_STATUS = {
  DRAFT: 'DRAFT',
  QA: 'QA',
  LIVE: 'LIVE',
};
