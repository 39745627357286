import { Component } from 'react';
import { authService } from 'services/auth';
import { getConfig } from 'config/get-config';

const { DOMAIN } = getConfig();

class Logout extends Component {
  async componentDidMount() {
    await authService.logout({
      returnTo: DOMAIN
    });
  }

  render() {
    return null;
  }
}

export { Logout };
