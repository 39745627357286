import React from 'react';
import { Form } from 'antd';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import * as Styles from 'components/questions-styles';
import { cloneDeep } from 'lodash';

const AnswersSection = props => {
  const { answersList = [], onAnswersChange, getFieldDecorator } = props;

  const remove = index => {
    const newAnswers = cloneDeep(answersList);
    newAnswers.splice(index, 1);
    onAnswersChange(newAnswers);
  };

  const add = () => {
    const newAnswers = cloneDeep(answersList);
    newAnswers.push({ answerId: uuid(), text: '', correct: false });
    onAnswersChange(newAnswers);
  };

  const onChange = (event, index) => {
    const newAnswers = cloneDeep(answersList);
    newAnswers[index].text = event.target.value;
    onAnswersChange(newAnswers);
  };

  const toggleCorrect = index => {
    const newAnswers = cloneDeep(answersList);
    newAnswers[index].correct = !newAnswers[index].correct;
    onAnswersChange(newAnswers);
  };

  const showAnswers = answers => {
    return answers.map((answer, index) => {
      return (
        <StyledFormItem key={index}>
          {getFieldDecorator(`${index}`, {
            initialValue: answer.text,
            rules: [
              {
                required: true,
                message: <Styles.FormErrorMsg message="Answer is required" />
              }
            ]
          })(
            <Styles.FlexCenter>
              <Styles.InputMR1
                value={answer.text}
                placeholder={`Answer #${index + 1}`}
                onChange={event => onChange(event, index)}
              />
              <Styles.TickIconBtn
                correct={answer.correct}
                onClick={() => toggleCorrect(index)}
              />
              <Styles.DeleteIconBtn onClick={() => remove(index)} />
            </Styles.FlexCenter>
          )}
        </StyledFormItem>
      );
    });
  };

  return (
    <>
      {showAnswers(answersList)}
      <Form.Item>
        <Styles.GreyBtn onClick={add} data-testid="new-answer-button">
          <Styles.StyledI className="fa fa-plus" /> New Answer
        </Styles.GreyBtn>
      </Form.Item>
    </>
  );
};

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0.65em;
`;

export { AnswersSection };
