import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Divider, Button, Radio, Checkbox, Modal, Tooltip } from 'antd';
import { Spinner } from 'components/spinner';

export const StyledSpinner = styled(Spinner)`
  display: inline-block;
`;

export const FormErrorMsg = ({ message }) => (
  <ErrorMsg>
    <StyledI className="fas fa-exclamation-circle" />
    {message}
  </ErrorMsg>
);

export const ErrorContainer = styled.div`
  margin-top: -1.125em;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export const ErrorMsg = styled.div`
  color: ${colours.red700};
  font-size: 14px;
  font-weight: 400;
`;

export const BlueIcon = styled.div`
  color: ${colours.blue700};
`;

export const GreenIcon = styled.div`
  color: ${colours.green700};
`;

export const PinkIcon = styled.div`
  color: ${colours.red700};
`;

export const OrangeIcon = styled.div`
  color: ${colours.orange700};
`;

export const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
  padding: 1em;
`;

export const StyledI = styled.i`
  margin-right: 0.5em;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderTitle = styled.div`
  color: ${colours.navy500};
  font-size: 1.625em;
  font-weight: 700;
`;

export const HeaderDesc = styled.div`
  color: ${colours.navy900};
  font-size: 0.85em;
  font-weight: 400;
`;

export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const GreenBtn = styled(Button)`
  background-color: ${colours.green700};
  border-radius: 3px;
  font-size: 1em;
  font-weight: 700;
  border: 0;
  color: #ffffff;

  &:hover,
  &:active &:focus {
    background-color: ${colours.green700};
    color: #ffffff;
  }
`;

export const GreyBtn = styled(Button)`
  margin-right: 0.5em;
  background-color: ${colours.lightGrey300};
  border-radius: 3px;
  color: ${colours.darkGrey700};
  font-size: 1em;
  font-weight: 700;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.lightGrey500};
    color: ${colours.darkGrey700};
  }

  &:disabled {
    background-color: ${colours.lightGrey200};
    color: ${colours.lightGrey900};
  }
`;

export const OrangeBtn = styled(Button)`
  background-color: ${colours.orange700};
  border-radius: 3px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.orange700};
    color: #ffffff;
  }
`;

export const RedBtn = styled(Button)`
  background-color: ${colours.red700};
  border-radius: 3px;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.red1000};
    color: #ffffff;
  }
`;

export const RedTexBtn = styled(Button)`
  border-radius: 3px;
  color: ${colours.red700};
  font-size: 1em;
  font-weight: 700;
  border: 0;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    color: ${colours.red1000};
  }
`;

export const UploadBtn = styled(Button)`
  display: flex;
  margin: 0.5em;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.div`
  color: ${colours.navy800};
  font-size: 2em;
  font-weight: 700;
`;

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionLabel = styled.div`
  color: ${colours.navy500};
  font-size: 1.375em;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const ItemLabel = styled.div`
  color: ${colours.navy700};
  font-size: 1.125em;
  font-weight: 600;
  margin-top: 1em;
`;

export const LabelDesc = styled.div`
  margin-bottom: 1em;
  color: ${colours.darkGrey400};
  font-size: 0.875em;
  font-weight: 400;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
`;

export const ThumbnailImg = styled.div`
  display: flex;
  flex-grow: 1;
  margin-right: 1em;
  border: 0;
`;

export const ThumbnailAction = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 1em;
  }
`;

export const VisibilityContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 3px 15px 0 rgba(7, 1, 82, 0.06);
`;

export const VisibilityPublicBox = ({ type = 'lab' }) => (
  <>
    <VisibilityPublic />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is visible.</VisibilityTitle>
      <VisibilityDesc>
        This {type} is discoverable via the Browse page and by direct link.
      </VisibilityDesc>
    </VisibilityInfo>
  </>
);

export const VisibilityPublic = styled.div`
  background-color: ${colours.green700};
  color: ${colours.green700};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityArchivedBox = ({ type = 'lab' }) => (
  <>
    <VisibilityArchived />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is archived.</VisibilityTitle>
      <VisibilityDesc>
        This {type} is completely inaccessible outside of content management
        tools.
      </VisibilityDesc>
    </VisibilityInfo>
  </>
);

export const VisibilityArchived = styled.div`
  background-color: ${colours.red600};
  color: ${colours.red600};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityDraftBox = ({ type = 'lab' }) => (
  <>
    <VisibilityDraft />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is in draft state.</VisibilityTitle>
      <VisibilityDesc>
        Make this {type} visible for users to access it.
      </VisibilityDesc>
    </VisibilityInfo>
  </>
);

export const VisibilityDraft = styled.div`
  background-color: ${colours.orange700};
  color: ${colours.orange700};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityUnlistedBox = ({ type = 'lab' }) => (
  <>
    <VisibilityUnlisted />
    <VisibilityInfo>
      <VisibilityTitle>This {type} is unlisted.</VisibilityTitle>
      <VisibilityDesc>
        This {type} is undiscoverable, but still accessible by direct link.
      </VisibilityDesc>
    </VisibilityInfo>
  </>
);

export const VisibilityUnlisted = styled.div`
  background-color: ${colours.red700};
  color: ${colours.red700};
  border-radius: 3px 0 0 3px;
  padding: 0.2em;
`;

export const VisibilityInfo = styled.div`
  padding: 1em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const VisibilityTitle = styled.div`
  margin-top: -3px;
  color: ${colours.darkGrey700};
  font-size: 0.875em;
  font-weight: 600;
`;

export const VisibilityDesc = styled.div`
  color: ${colours.darkGrey400};
  font-size: 0.8125em;
  font-weight: 400;
`;

export const RadioBtnText = styled.div`
  margin-top: -3px;
  color: ${colours.darkGrey700};
  font-size: 1em;
  font-weight: 600;
`;

export const StyledRadio = styled(Radio)`
  display: flex;
  align-items: flex-start;
  width: 45%;
  margin-bottom: 1em;

  .ant-radio-checked .ant-radio-inner {
    border-color: ${colours.blue700};
    background-color: ${colours.blue700};

    &::after {
      background-color: #ffffff;
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
  > * {
    display: flex;
    align-items: center;
    width: 45%;
    margin-bottom: 1em;
    color: ${colours.darkGrey700};
    font-size: 0.875em;
    font-weight: 600;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${colours.darkGrey700};
  font-size: 0.875em;
  font-weight: 600;
`;

export const VideoSourceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const VideoComponentInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 1em;
  border: 0;
`;

export const VideoComponentAction = styled.div`
  display: flex;
  align-items: center;
`;

export const VideoComponentDivider = styled(Divider)`
  margin: 0;
`;

export const Popular = () => (
  <PopularContainer>
    <StyledI className="fa fa-star" /> Popular
  </PopularContainer>
);

export const PopularContainer = styled.div`
  display: flex;
  align-items: center;
  height: 1.125em;
  padding: 0.8em;
  margin-left: 1em;
  background-color: ${colours.yellow700};
  border-radius: 9px;
  color: #ffffff;
  font-size: 0.75em;
  font-weight: 500;
`;

export const VideoPrimary = () => (
  <VideoPrimaryContainer>
    <StyledI className="fa fa-circle" /> Primary
  </VideoPrimaryContainer>
);

export const VideoPrimaryContainer = styled.div`
  display: flex;
  align-items: center;
  height: 1.125em;
  padding: 0.8em;
  margin-left: 1em;
  background-color: ${colours.green700};
  border-radius: 9px;
  color: #ffffff;
  font-size: 0.75em;
  font-weight: 500;
`;

export const StyledPlayerModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayBtn = props => (
  <Tooltip title="Play">
    <StyledVideoBtn onClick={props.onClick}>
      <StyledI className="fas fa-play-circle" />
    </StyledVideoBtn>
  </Tooltip>
);

export const DeleteBtn = props => (
  <Tooltip title="Delete">
    <StyledVideoBtn onClick={props.onClick}>
      <StyledI className="fas fa-trash-alt" />
    </StyledVideoBtn>
  </Tooltip>
);

export const MakePrimaryBtn = props => (
  <Tooltip title="Make Primary">
    <StyledVideoBtn onClick={props.onClick}>
      <StyledI className="fas fa-certificate" />
    </StyledVideoBtn>
  </Tooltip>
);

export const StyledVideoBtn = styled(Button)`
  border: 0;
  padding: 0;
  &:hover,
  &:active,
  &:focus {
    color: ${colours.darkGrey900};
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

export const ResourceInfo = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1.125em 0 1.125em;
`;

export const ResourceAction = styled.div`
  display: flex;
  align-items: center;
`;

export const ResourceURL = styled.div`
  color: ${colours.navy700};
  font-size: 0.875em;
  font-weight: 400;
`;

export const ResourceTitle = styled.div`
  color: ${colours.navy700};
  font-size: 1em;
  font-weight: 600;
  margin-top: 1em;
`;
