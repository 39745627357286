import { get, isEmpty, filter, every, has } from 'lodash';

export const validateQuestionForQa = question => {
  const questionText = get(question, 'questionText', null);

  if (isEmpty(questionText)) {
    return 'questionText is required';
  }

  const answers = get(question, 'answers', []);

  if (isEmpty(answers)) {
    return 'At least one correct answer is required';
  }

  if (
    !every(answers, answer => {
      return !!answer.text;
    })
  ) {
    return 'text is required';
  }

  if (
    !every(answers, answer => {
      return has(answer, 'correct');
    })
  ) {
    return 'correct is required';
  }

  if (isEmpty(filter(answers, ['correct', true]))) {
    return 'At least one correct answer is required';
  }

  return null;
};
