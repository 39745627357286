/* @jsx jsx */
import React from 'react';
// eslint-disable-next-line
import { jsx, Styled } from 'theme-ui';
import styled from 'styled-components';
import LogoSrc from 'assets/images/logo.png';
import { Menu, Icon, Box, colours } from '@a-cloud-guru/rainbow-ui';
import { Link, NavLink } from 'react-router-dom';

import { ReactComponent as LearningPathIcon } from 'assets/images/nav-learning-paths.svg';
import { ReactComponent as ImageUploadIcon } from 'assets/images/nav-image-upload.svg';
import { ReactComponent as QuestionEngineIcon } from 'assets/images/nav-question-engine.svg';
import { ReactComponent as ReferenceDataIcon } from 'assets/images/nav-reference-data.svg';
import { ReactComponent as TagsIcon } from 'assets/images/nav-tags.svg';

const MenuItem = Menu.Item;
const MenuItemGroup = Menu.ItemGroup;

const Nav = props => {
  return (
    <StyledContainer>
      <Box className="SiteLogo__wide" padding="s4">
        <Link to="/">
          <Logo src={LogoSrc} sx={{ mb: 's1' }} />
          <Styled.h1 sx={{ color: '#FFF', fontSize: '28px', margin: '4px 0 4px 0' }}>Content Admin</Styled.h1>
        </Link>
      </Box>
      <Menu theme="dark" mode="inline">
        <MenuItemGroup title="Content" key="1">
          <MenuItem key="13">
            <NavLink to="/learning-paths">
              <Icon component={LearningPathIcon} />
              <span>Learning Paths</span>
            </NavLink>
          </MenuItem>
        </MenuItemGroup>
        <MenuItemGroup title="Tools" key="2">
          <MenuItem key="21">
            <NavLink to="/questions">
              <Icon component={QuestionEngineIcon} />
              <span>Questions</span>
            </NavLink>
          </MenuItem>
          <MenuItem key="22">
            <NavLink to="/image-upload">
              <Icon component={ImageUploadIcon} />
              <span>Image Uploader</span>
            </NavLink>
          </MenuItem>
        </MenuItemGroup>
        <MenuItemGroup title="Reference Data" key="3">
          <MenuItem key="31">
            <NavLink to="/tags">
              <Icon component={TagsIcon} />
              <span>Tags</span>
            </NavLink>
          </MenuItem>
          <MenuItem key="32">
            <NavLink to="/course-recommender">
              <Icon component={ReferenceDataIcon} />
              <span>Course Recommender</span>
            </NavLink>
          </MenuItem>
          <MenuItem key="33">
            <NavLink to="/roles-goals">
              <Icon component={ReferenceDataIcon} />
              <span>Roles And Goals</span>
            </NavLink>
          </MenuItem>
        </MenuItemGroup>
      </Menu>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
.ant-menu-item-group-title {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 700;
  line-height: 14px;
  color: ${colours.navy400};
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  display: none;
}

.ant-menu-item {
  padding-left: 18px;

  a {
    font-weight: 600;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
  i {
    vertical-align: middle;
  }
}

.ant-menu-item i svg {
  width: 24px;
  height: 24px;
  margin-top: -1px;

  g {
    fill: ${colours.navy400};
    transition: fill .1s ease-in-out;
  }
}
.ant-menu-item-active i svg g {
  fill: #FFF;
}

.ant-menu-item-group {
  margin-bottom: 28px;
}
`;

const Logo = styled.img`
  padding-right: 1.5em;
  flex-shrink: 0;
  height: 24px;
`;

export { Nav };
