import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';

export const Dashboard = props => (
  <>
    <Container>
      <div>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </div>
      <Buttons>{props.button}</Buttons>
    </Container>
    {props.children}
  </>
);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const Buttons = styled.div`
  display: flex;

  > * {
    margin-left: 1em;
  }
`;

const Title = styled.div`
  color: ${colours.navy500};
  font-size: 1.625em;
  font-weight: 700;
  text-align: left;
`;

const Description = styled.div`
  color: ${colours.navy900};
  font-size: 1.125em;
  font-weight: 400;
  text-align: left;
`;
