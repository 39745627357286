import gql from 'graphql-tag';
import { getSignedUrl, uploadToS3 } from './s3upload';

const GET_ATTACHMENT_UPLOAD_URL = gql`
  query attachmentUploadUrl($filename: String!) {
    attachmentUploadUrl(filename: $filename) {
      url
    }
  }
`;

export const s3AttachmentUpload = async (
  apolloClient,
  file,
  onUploadProgress
) => {
  const result = await getSignedUrl(
    apolloClient,
    file.name,
    GET_ATTACHMENT_UPLOAD_URL
  );

  const { attachmentUploadUrl } = result;

  if (!attachmentUploadUrl.url) {
    throw new Error('Could not get signed url for upload.');
  }

  const signedUrl = attachmentUploadUrl.url;
  const url = await uploadToS3(signedUrl, file, onUploadProgress);

  return url;
};
