import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { s3ImageUpload } from 'lib/upload-image-s3';
import { Container } from './ResourceUploader';
import * as LabStyles from 'components/labsstyles';
import { ImageOptimizer } from 'components/image/ImageOptimizer';

const ArtworkUploader = props => {
  const [files, setFiles] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [imageUrl, setImageUrl] = useState();
  const [uploadingStatus, setUploadingStatus] = useState(false);

  const { apolloClient } = props;

  const onDrop = useCallback(acceptedFiles => {
    setFiles(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 5368709120, // 5Gb
    accept: 'image/jpeg, image/jpg, image/png'
  });

  const onUploadProgress = event => {
    const completed = Math.round((event.loaded * 100) / event.total);
    setPercentage(completed);
  };

  const selectedImg = files.map(file => (
    <ImageOptimizer key={file.name} width={500} height={281}>
      {({ buildOptimizedSrc }) => <StyledImg alt="" src={file.preview} />}
    </ImageOptimizer>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const onUpload = async e => {
    setUploadingStatus(true);
    const imageUrl = await s3ImageUpload(apolloClient, files[0], onUploadProgress);
    setUploadingStatus(false);
    setImageUrl(imageUrl);
    props.onUpload(imageUrl);
  };

  return (
    <>
      <LabStyles.FileContainer>
        <ImgContainer>{selectedImg}</ImgContainer>
        <Container {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <p>Drop the file here ...</p>
            </>
          ) : (
            <>
              <p>Drag 'n' drop the file here, or click to select the file</p>
            </>
          )}
          <em>(Only *.jpeg, *.jpg and *.png images will be accepted)</em>
        </Container>
        {!isEmpty(files[0]) && (
          <LabStyles.UploadBtn onClick={onUpload} disabled={uploadingStatus}>
            Upload{uploadingStatus && `ing: ${percentage}%`}
          </LabStyles.UploadBtn>
        )}
      </LabStyles.FileContainer>
      {!isEmpty(imageUrl) && (
        <ImgContainer>
          <ImageUrl>{imageUrl}</ImageUrl>
        </ImgContainer>
      )}
    </>
  );
};

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  max-height: 281px;
  max-width: 500px;
  margin-bottom: 1.5em;
`;

const ImageUrl = styled.pre`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  text-align: left;
  margin-top: 1.5px;
`;

export default ArtworkUploader;
