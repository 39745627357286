import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { withAuthorization } from './lib/withAuthorization';
import { Home } from 'routes/Home';
import { Logout } from 'routes/Logout';
import { LoginCallback } from 'routes/Login';
import { RolesAndGoals } from 'routes/RolesAndGoals';
import { CourseRecommender } from 'routes/CourseRecommender';
import { LearningPaths } from 'routes/LearningPaths/LearningPaths';
import { LearningPath } from 'routes/LearningPaths/LearningPath';
import { Questions } from 'routes/Questions';
import { NewQuestion } from 'routes/Questions/Form/NewQuestion';
import { EditQuestion } from 'routes/Questions/Form/EditQuestion';
import { QuestionImport } from 'routes/Questions/Form/QuestionImport';
import { QuestionBankReport } from 'routes/Questions/QuestionBankReport';
import { ImageUploader } from 'routes/ImageUpload';
import { Tags } from 'routes/Tags/Tags';
import { TagsCategory } from 'routes/Tags/TagsCategory';

const imgUploadRoles = ['ADMIN', 'EDITOR'];

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route
            exact
            path="/"
            component={withAuthorization(withApollo(Home))}
          />
          <Route
            exact
            path="/learning-paths"
            component={withAuthorization(withApollo(LearningPaths))}
          />
          <Route
            path="/learning-paths/:id"
            component={withAuthorization(withApollo(LearningPath))}
          />
          <Route
            path="/course-recommender"
            component={withAuthorization(withApollo(CourseRecommender))}
          />
          <Route
            path="/roles-goals"
            component={withAuthorization(withApollo(RolesAndGoals))}
          />
          <Route
            path="/questions"
            component={withAuthorization(withApollo(Questions))}
          />
          <Route
            path="/question/report"
            component={withAuthorization(withApollo(QuestionBankReport))}
          />
          <Route
            exact
            path="/question/new"
            component={withAuthorization(withApollo(NewQuestion))}
          />
          <Route
            exact
            path="/question/import"
            component={withAuthorization(withApollo(QuestionImport))}
          />
          <Route
            exact
            path="/question/edit/:id"
            component={withAuthorization(withApollo(EditQuestion))}
          />
          <Route
            exact
            path="/image-upload"
            component={withAuthorization(withApollo(ImageUploader), imgUploadRoles)}
          />
          <Route
            exact
            path="/tags"
            component={withAuthorization(withApollo(Tags))}
          />
          <Route
            exact
            path="/tags/:category"
            component={withAuthorization(withApollo(TagsCategory))}
          />
          <Route path="/login/callback" component={LoginCallback} />
          <Route path="/logout" component={Logout} />
        </div>
      </Router>
    );
  }
}

export default App;
