import urlJoin from 'url-join';
import { makeAuthService } from '@a-cloud-guru/auth0-wrapper';
import { getConfig } from 'config/get-config';

const { 
  AUTH0_CLIENT_ID, 
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  DOMAIN 
} = getConfig();

const authService = makeAuthService({
  AUTH0_CLIENT_ID, 
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  DEFAULT_REDIRECT_URI: urlJoin(DOMAIN, 'login', 'callback')
});


export {
  authService
};
