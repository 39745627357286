import React, { useState } from 'react';
import { Cascader, Input } from 'antd';
import { Container, FilterBox, FilterBtn, FilterLabel } from './Styles';
import * as Styles from 'components/questions-styles';
import { alphabeticallySort } from 'lib/alphabetically-sort';

const Search = Input.Search;

export const QuestionSearchBar = props => {
  const [searchInput, setSearchInput] = useState('');
  const { onFilterQuestions, onSearchQuestions, roles } = props;

  const getRolesFilters = () =>
    alphabeticallySort(
      roles.map(role => ({ label: role.value, value: role.value }))
    );

  const getFiltersOptions = () => {
    const roleFilter = getRolesFilters();

    const options = [
      {
        value: 'questionStatus',
        label: 'Question Status',
        children: [
          { label: 'Flagged for QA', value: 'QA_FLAGGED' },
          { label: 'Live', value: 'LIVE' },
          { label: 'Draft', value: 'DRAFT' },
          { label: 'QA', value: 'QA' },
          { label: 'Hidden', value: 'HIDDEN' },
          { label: 'Decommissioned', value: 'DECOMMISSIONED' }
        ]
      },
      {
        value: 'question.cloudProviders',
        label: 'Cloud Provider',
        children: [
          { label: 'AWS', value: 'AWS' },
          { label: 'AZURE', value: 'AZURE' },
          { label: 'GCP', value: 'GCP' },
          { label: 'OTHER', value: 'OTHER' }
        ]
      },
      {
        value: 'question.disciplines.role',
        label: 'Role',
        children: [...roleFilter, { label: 'Other', value: 'OTHER' }]
      },
      {
        value: 'question.disciplines.skillLevel',
        label: 'Skill Level',
        children: [
          { label: 'Novice', value: 'Novice' },
          { label: 'Apprentice', value: 'Apprentice' },
          { label: 'Practitioner', value: 'Practitioner' },
          { label: 'Professional', value: 'Professional' },
          { label: 'Guru', value: 'Guru' },
          { label: 'Other', value: 'OTHER' }
        ]
      },
      {
        value: 'question.assessmentType',
        label: 'Question Type',
        children: [
          { label: 'Course Quiz', value: 'COURSE' },
          { label: 'Exam Simulator', value: 'EXAM_SIMULATOR' },
          { label: 'Skill Assessment', value: 'SKILL_ASSESSMENT' },
          { label: 'Other', value: 'OTHER' }
        ]
      }
    ];

    return options;
  };

  const onFilterChange = (value, _) => {
    const filter = { type: value[0], value: value[1] };
    onFilterQuestions(filter);
  };

  const onChange = e => {
    setSearchInput(e.target.value);
  };

  const onSearch = params => {
    onSearchQuestions({ query: params, filters: '' });
    setSearchInput('');
  };

  return (
    <Container>
      <FilterBox>
        <Cascader options={getFiltersOptions()} onChange={onFilterChange}>
          <FilterBtn>
            <FilterLabel>
              <Styles.StyledI className="fa fa-filter" /> Filters
            </FilterLabel>
          </FilterBtn>
        </Cascader>
      </FilterBox>
      <Search
        onChange={onChange}
        value={searchInput}
        placeholder="Search for a question..."
        onSearch={onSearch}
      />
    </Container>
  );
};
