import React, { useRef, useState, useEffect } from 'react';
import gql from 'graphql-tag';
import styled, { css } from 'styled-components';
import { isEmpty, get } from 'lodash';
import { colours, Button, Dropdown, Input, Menu, Modal, Tag, Empty, message } from '@a-cloud-guru/rainbow-ui';
import * as Bluebird from 'bluebird';
import * as LabStyles from 'components/labsstyles';
import * as Styles from 'components/questions-styles';
import { Loading } from 'components/loading/Loading';
import { PaginatedTable } from 'components/table/PaginatedTable';
import { QuestionSearchBar } from 'components/search/QuestionSearchBar';
import { QUESTION_STATUS, OTHER_SKILL_LEVELS, OTHER_CLOUD_PROVIDERS, OTHER_ASSESSMENT_TYPES } from './Form/constants';
import { getAssessmentMetadata } from 'lib/get-questions-assessment-metadata';

const PAGE_SIZE = 20;

export const SEARCH_QUESTION = gql`
  query ACG_searchQuestions($searchInput: QuestionSearchInput!) {
    ACG_searchQuestions(searchInput: $searchInput) {
      questions {
        questionId
        authorId
        isFlaggedForQA
        questionStatus
        lastUpdated
        question {
          questionText
          disciplines {
            skillLevel
            role
          }
        }
      }
      facets {
        roles {
          name
          count
        }
        skillLevel {
          name
          count
        }
      }
    }
  }
`;

const QUESTION_STATUS_MUTATION = gql`
  mutation ACG_updateQuestion($question: QuestionInput!) {
    ACG_updateQuestion(question: $question) {
      questionId
      isFlaggedForQA
      questionStatus
    }
  }
`;

export const Pagination = props => {
  const initialLoad = useRef(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [questions, setQuestions] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchFilters, setSearchFilters] = useState({
    query: '',
    filters: ''
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [roles, setRoles] = useState([]);
  const [otherRoles, setOtherRoles] = useState(null);

  const [statusText, setStatusText] = useState('');

  const [toggleFlagQAModal, setToggleFlagQAModal] = useState(false);

  const [toggleQAModal, setToggleQAModal] = useState(false);
  const [toggleDraftModal, setToggleDraftModal] = useState(false);
  const [togglePublishModal, setTogglePublishModal] = useState(false);
  const [toggleHideModal, setToggleHideModal] = useState(false);
  const [toggleDecommissionModal, setToggleDecommissionModal] = useState(false);

  const { apolloClient } = props;

  useEffect(() => {
    if (!initialLoad.current) {
      return;
    }

    initialLoad.current = false;

    if (!loaded) {
      getQuestions();
    }

    if (isEmpty(roles)) {
      getAssessmentMetadata(apolloClient).then(res => {
        const rolesList = get(res, 'roles', []);
        if (!isEmpty(rolesList)) {
          const r = rolesList.map(role => `NOT question.disciplines.role:"${role.value}"`).join(' AND ');

          setOtherRoles(r);
        }
        return setRoles(rolesList);
      });
    }

    return () => {
      setLoaded(false);
      setQuestions(null);
      setRoles([]);
      setOtherRoles(null);
      return setSearchInput('');
    };
  });

  const getQuestions = (
    searchInput = {
      query: ''
    }
  ) => {
    setLoading(true);
    setQuestions(null);
    return apolloClient
      .query({
        query: SEARCH_QUESTION,
        variables: { searchInput },
        fetchPolicy: 'no-cache'
      })
      .then(({ data }) => {
        const results = get(data.ACG_searchQuestions, 'questions', null);
        setLoaded(true);
        setQuestions(results);
        return setLoading(false);
      });
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const showFlagQAModal = () => {
    setToggleFlagQAModal(true);
  };

  const onFlagQAConfirm = async e => {
    try {
      e.preventDefault();
      await toggleFlagForQA(true);
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setToggleFlagQAModal(false);
    }
  };

  const onFlagQACancel = e => {
    setStatusText('');
    setUpdating(false);
    setToggleFlagQAModal(false);
  };

  const showQAModal = () => {
    setToggleQAModal(true);
  };

  const onQAConfirm = async e => {
    try {
      e.preventDefault();
      await updateStatus(QUESTION_STATUS.QA);
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setToggleQAModal(false);
    }
  };

  const onQACancel = e => {
    setStatusText('');
    setUpdating(false);
    setToggleQAModal(false);
  };

  const showDraftModal = () => {
    setToggleDraftModal(true);
  };

  const onDraftConfirm = async e => {
    try {
      e.preventDefault();
      await updateStatus(QUESTION_STATUS.DRAFT);
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setToggleDraftModal(false);
    }
  };

  const onDraftCancel = e => {
    setStatusText('');
    setUpdating(false);
    setToggleDraftModal(false);
  };

  const showPublishModal = () => {
    setTogglePublishModal(true);
  };

  const onPublishConfirm = async e => {
    try {
      e.preventDefault();
      await updateStatus(QUESTION_STATUS.LIVE);
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setTogglePublishModal(false);
    }
  };

  const onPublishCancel = e => {
    setStatusText('');
    setUpdating(false);
    setTogglePublishModal(false);
  };

  const showHideModal = () => {
    setToggleHideModal(true);
  };

  const onHideConfirm = async e => {
    try {
      e.preventDefault();
      await updateStatus(QUESTION_STATUS.HIDDEN);
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setToggleHideModal(false);
    }
  };

  const onHideCancel = e => {
    setStatusText('');
    setUpdating(false);
    setToggleHideModal(false);
  };

  const showDecommissionModal = () => {
    setToggleDecommissionModal(true);
  };

  const onDecommissionConfirm = async e => {
    try {
      e.preventDefault();
      await updateStatus(QUESTION_STATUS.DECOMMISSIONED);
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setToggleDecommissionModal(false);
    }
  };

  const onDecommissionCancel = e => {
    setStatusText('');
    setUpdating(false);
    setToggleDecommissionModal(false);
  };

  const updateStatus = async questionStatus => {
    try {
      setUpdating(true);
      await Bluebird.map(
        selectedRowKeys,
        questionId =>
          apolloClient.mutate({
            mutation: QUESTION_STATUS_MUTATION,
            variables: {
              question: {
                questionId,
                questionStatus
              }
            }
          }),
        { concurrency: 20 }
      );
      message.success(`${selectedRowKeys.length} questions updated.`);
    } catch (error) {
      console.log('Error: ', error);
      message.error('Something went wrong: Failed to update some questions.');
    } finally {
      reset();
    }
  };

  const toggleFlagForQA = async isFlaggedForQA => {
    const note = isFlaggedForQA ? 'Flagged for QA' : 'Remove QA flag';

    try {
      setUpdating(true);
      await Bluebird.map(
        selectedRowKeys,
        questionId =>
          apolloClient.mutate({
            mutation: QUESTION_STATUS_MUTATION,
            variables: {
              question: {
                questionId,
                isFlaggedForQA,
                editorNote: note
              }
            }
          }),
        { concurrency: 20 }
      );
      const msg = `${selectedRowKeys.length} question${selectedRowKeys.length > 1 ? 's' : ''} ${
        isFlaggedForQA ? 'flagged for QA.' : 'unflagged'
        }`;
      message.success(msg);
    } catch (error) {
      console.log('Error: ', error);
      const msg = `Something went wrong: Failed to ${isFlaggedForQA ? 'flag' : 'unflag'} some questions.`;
      message.error(msg);
    } finally {
      reset();
    }
  };

  const reset = () => {
    setUpdating(false);
    setSearchSubmitted(false);

    setSelectedRowKeys([]);

    setStatusText('');
    setSearchInput('');

    setSearchFilters({
      query: '',
      filters: ''
    });

    setOtherRoles(null);
    showSearchResults(null);
    setQuestions(null);

    getQuestions();
  };

  const buildSearchFilters = ({ type, value }) => {
    if (type === 'question.cloudProviders' && value === 'OTHER') {
      const input = {
        query: '',
        filters: OTHER_CLOUD_PROVIDERS
      };
      return input;
    }

    if (type === 'question.disciplines.skillLevel' && value === 'OTHER') {
      const input = {
        query: '',
        filters: OTHER_SKILL_LEVELS
      };
      return input;
    }

    if (type === 'question.assessmentType' && value === 'OTHER') {
      const input = {
        query: '',
        filters: OTHER_ASSESSMENT_TYPES
      };
      return input;
    }

    if (type === 'question.disciplines.role' && value === 'OTHER') {
      const input = {
        query: '',
        filters: otherRoles || ''
      };
      return input;
    }

    if (type === 'questionStatus' && value === 'QA_FLAGGED') {
      const input = {
        query: '',
        filters: 'isFlaggedForQA:true'
      };
      return input;
    }

    const input = {
      query: '',
      filters: `${type}:"${value}"`
    };

    return input;
  };

  const buildText = text => {
    var mapObj = {
      question: '',
      disciplines: '',
      cloudProviders: 'Cloud Provider',
      skillLevel: 'Skill Level',
      assessmentType: 'Type'
    };

    let str = text;

    if (!isEmpty(otherRoles)) {
      str = text.replace(otherRoles, 'Other Roles');
    }

    str = str
      .replace('isFlaggedForQA:true', 'Flagged for QA')
      .replace(OTHER_CLOUD_PROVIDERS, 'Other Cloud Providers')
      .replace(OTHER_SKILL_LEVELS, 'Other Skill Levels')
      .replace(OTHER_ASSESSMENT_TYPES, 'Other Assessment Types')
      .replace(/question|disciplines|cloudProviders|skillLevel|assessmentType/gi, matched => mapObj[matched])
      .replace(/\./g, '')
      .replace(/["']/g, '')
      .replace(/[:]/g, ': ');

    return str;
  };

  const onFilterQuestions = filter => {
    const { type, value } = filter;
    if (!type) {
      return resetSearch();
    }

    const input = buildSearchFilters({ type, value });

    const newInput = {
      query: searchFilters.query,
      filters: isEmpty(input.filters)
        ? isEmpty(searchFilters.filters)
          ? ''
          : searchFilters.filters
        : isEmpty(searchFilters.filters)
          ? `${input.filters}`
          : `${searchFilters.filters} AND ${input.filters}`
    };

    let text = isEmpty(searchFilters.query) ? newInput.filters : `${searchFilters.query} AND ${newInput.filters}`;

    text = buildText(text);

    setSearchInput(text);
    setSearchFilters(newInput);
    return searchQuestions(newInput);
  };

  const onSearchQuestions = input => {
    if (isEmpty(input.query)) {
      return;
    }

    setSearchFilters(input);
    setSearchInput(input.query);
    searchQuestions(input);
  };

  const searchQuestions = input => {
    setLoading(true);
    setSearchSubmitted(true);
    getQuestions(input);
  };

  const resetSearch = e => {
    reset();
  };

  const showSearchResults = searchQuery => {
    if (isEmpty(searchQuery)) {
      return null;
    }

    return (
      <>
        <MsgContainer>
          {searchQuery.split('AND').map((text, index) => (
            <StyledTag key={index}>
              <InnerTag text={text} />
            </StyledTag>
          ))}

          <Button type="link" size="small" onClick={resetSearch}>
            Clear Search
          </Button>

        </MsgContainer>

        {isEmpty(questions) && searchSubmitted && !loading &&
          <Empty description="No results found">
            <Button type="primary" onClick={resetSearch}>Clear Search</Button>
          </Empty>
        }
      </>
    );
  };

  const InnerTag = ({ text }) => {
    if (text.indexOf(': ') < 0) {
      return text;
    } else {
      return <>
        <span style={{ marginRight: '3px' }}>{text.split(': ')[0]}:</span>
        <strong>{text.split(': ')[1]}</strong>
      </>
    }
  }

  const menu = (
    <StyledMenu>
      <Menu.Item key="0">
        <span onClick={showDraftModal}>
          <Styles.StyledI className="fa fa-edit" /> Return to Draft
        </span>
      </Menu.Item>
      <Menu.Item key="1">
        <span onClick={showQAModal}>
          <Styles.StyledI className="fa fa-question-circle" /> Submit to QA
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span onClick={showPublishModal}>
          <Styles.StyledI className="fa fa-arrow-circle-up" /> Publish
        </span>
      </Menu.Item>
      <Menu.Item key="3">
        <span onClick={showHideModal}>
          <Styles.StyledI className="fa fa-eye-slash" /> Hide
        </span>
      </Menu.Item>
      <Menu.Item key="4">
        <span onClick={showDecommissionModal}>
          <Styles.StyledI className="fa fa-lock" /> Decommission
        </span>
      </Menu.Item>
    </StyledMenu>
  );

  const showTable = () => {
    return (
      <PaginatedTable data-testid="paginated-table"
        title={() => (
          <TableHeader selected={selectedRowKeys.length > 0}>
            <HeaderText>{selectedRowKeys.length} questions selected</HeaderText>
            <Styles.FlexContainer>
              {/* Not yet implemented */}
              {/* <Styles.WhiteBtn>Course</Styles.WhiteBtn>
              <Styles.WhiteBtn>Chapter</Styles.WhiteBtn>
              <Styles.WhiteBtn>Assessment</Styles.WhiteBtn> */}
              {selectedRowKeys.length > 0 && (
                <>
                  <Button type="danger" size="small" onClick={showFlagQAModal} mr={'sm'}>
                    Flag for QA
                  </Button>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button size="small">Status</Button>
                  </Dropdown>
                </>
              )}
            </Styles.FlexContainer>
          </TableHeader>
        )}
        rowSelection={rowSelection}
        dataSource={loading ? [] : questions}
        rowKey="questionId"
        pagination={{
          current: page,
          pageSize: PAGE_SIZE,
          total: loading ? 0 : questions.length,
          onChange: current => setPage(current)
        }}
      />
    );
  };

  const renderModals = () => {
    return (
      <>
        <Modal
          title={<LabStyles.ModalTitle>Flag for QA</LabStyles.ModalTitle>}
          okText="Submit"
          visible={toggleFlagQAModal}
          onOk={onFlagQAConfirm}
          okButtonProps={{
            disabled: statusText !== 'FLAGGED' || updating
          }}
          onCancel={onFlagQACancel}
          footer={[
            <Button key="back" onClick={onFlagQACancel}>
              Cancel
            </Button>,
            <Button type="positive" key="save" onClick={onFlagQAConfirm} disabled={statusText !== 'FLAGGED' || updating}>
              Submit{updating && 'ting'}
            </Button>
          ]}
        >
          <ConfirmText>
            You are about to flag {selectedRowKeys.length} question
            {selectedRowKeys.length > 1 && 's'} for QA.
          </ConfirmText>
          <ConfirmText>Are you sure you want to do this?</ConfirmText>
          <ConfirmLabel>Type FLAGGED to confirm: </ConfirmLabel>
          <StyledInput value={statusText} onChange={e => setStatusText(e.target.value)} />
        </Modal>
        <Modal
          title={<LabStyles.ModalTitle>Submit to QA</LabStyles.ModalTitle>}
          okText="Submit"
          visible={toggleQAModal}
          onOk={onQAConfirm}
          okButtonProps={{
            disabled: statusText !== QUESTION_STATUS.QA || updating
          }}
          onCancel={onQACancel}
          footer={[
            <Button key="back" onClick={onQACancel}>
              Cancel
            </Button>,
            <Button type="positive"
              key="save"
              onClick={onQAConfirm}
              disabled={statusText !== QUESTION_STATUS.QA || updating}
            >
              Submit{updating && 'ting'}
            </Button>
          ]}
        >
          <ConfirmText>
            You are about to submit {selectedRowKeys.length} question
            {selectedRowKeys.length > 1 && 's'} to QA.
          </ConfirmText>
          <ConfirmText>This could result in broken assessments.</ConfirmText>
          <ConfirmText>Are you sure you want to do this?</ConfirmText>
          <ConfirmLabel>Type {QUESTION_STATUS.QA} to confirm: </ConfirmLabel>
          <StyledInput value={statusText} onChange={e => setStatusText(e.target.value)} />
        </Modal>
        <Modal
          title={<LabStyles.ModalTitle>Return to Draft</LabStyles.ModalTitle>}
          okText="Submit"
          visible={toggleDraftModal}
          onOk={onDraftConfirm}
          okButtonProps={{
            disabled: statusText !== QUESTION_STATUS.DRAFT || updating
          }}
          onCancel={onDraftCancel}
          footer={[
            <Button key="back" onClick={onDraftCancel}>
              Cancel
            </Button>,
            <Button type="positive"
              key="save"
              onClick={onDraftConfirm}
              disabled={statusText !== QUESTION_STATUS.DRAFT || updating}
            >
              Submit{updating && 'ting'}
            </Button>
          ]}
        >
          <ConfirmText>
            You are about to return {selectedRowKeys.length} question
            {selectedRowKeys.length > 1 && 's'} to Draft.
          </ConfirmText>
          <ConfirmText>This could result in broken assessments.</ConfirmText>
          <ConfirmText>Are you sure you want to do this?</ConfirmText>
          <ConfirmLabel>Type {QUESTION_STATUS.DRAFT} to confirm: </ConfirmLabel>
          <StyledInput value={statusText} onChange={e => setStatusText(e.target.value)} />
        </Modal>
        <Modal
          title={<LabStyles.ModalTitle>Publish Question{selectedRowKeys.length > 1 && 's'}</LabStyles.ModalTitle>}
          okText="Submit"
          visible={togglePublishModal}
          onOk={onPublishConfirm}
          okButtonProps={{
            disabled: statusText !== QUESTION_STATUS.LIVE || updating
          }}
          onCancel={onPublishCancel}
          footer={[
            <Button key="back" onClick={onPublishCancel}>
              Cancel
            </Button>,
            <Button type="positive"
              key="save"
              onClick={onPublishConfirm}
              disabled={statusText !== QUESTION_STATUS.LIVE || updating}
            >
              Submit{updating && 'ting'}
            </Button>
          ]}
        >
          <ConfirmText>
            You are about to publish {selectedRowKeys.length} question
            {selectedRowKeys.length > 1 && 's'}.
          </ConfirmText>
          <ConfirmText>Are you sure you want to do this?</ConfirmText>
          <ConfirmLabel>Type {QUESTION_STATUS.LIVE} to confirm: </ConfirmLabel>
          <StyledInput value={statusText} onChange={e => setStatusText(e.target.value)} />
        </Modal>
        <Modal
          title={<LabStyles.ModalTitle>Hide Question{selectedRowKeys.length > 1 && 's'}</LabStyles.ModalTitle>}
          okText="Submit"
          visible={toggleHideModal}
          onOk={onHideConfirm}
          okButtonProps={{
            disabled: statusText !== QUESTION_STATUS.HIDDEN || updating
          }}
          onCancel={onHideCancel}
          footer={[
            <Button key="back" onClick={onHideCancel}>
              Cancel
            </Button>,
            <Button type="positive"
              key="save"
              onClick={onHideConfirm}
              disabled={statusText !== QUESTION_STATUS.HIDDEN || updating}
            >
              Submit{updating && 'ting'}
            </Button>
          ]}
        >
          <ConfirmText>
            You are about to hide {selectedRowKeys.length} question
            {selectedRowKeys.length > 1 && 's'}.
          </ConfirmText>
          <ConfirmText>This could result in broken assessments.</ConfirmText>
          <ConfirmText>Are you sure you want to do this?</ConfirmText>
          <ConfirmLabel>Type {QUESTION_STATUS.HIDDEN} to confirm: </ConfirmLabel>
          <StyledInput value={statusText} onChange={e => setStatusText(e.target.value)} />
        </Modal>
        <Modal
          title={<LabStyles.ModalTitle>Decommission Question{selectedRowKeys.length > 1 && 's'}</LabStyles.ModalTitle>}
          okText="Submit"
          visible={toggleDecommissionModal}
          onOk={onDecommissionConfirm}
          okButtonProps={{
            disabled: statusText !== QUESTION_STATUS.DECOMMISSIONED || updating
          }}
          onCancel={onDecommissionCancel}
          footer={[
            <Button key="back" onClick={onDecommissionCancel}>
              Cancel
            </Button>,
            <Button type="positive"
              key="save"
              onClick={onDecommissionConfirm}
              disabled={statusText !== QUESTION_STATUS.DECOMMISSIONED || updating}
            >
              Submit{updating && 'ting'}
            </Button>
          ]}
        >
          <ConfirmText>
            You are about to decommission {selectedRowKeys.length} question
            {selectedRowKeys.length > 1 && 's'}.
          </ConfirmText>
          <ConfirmText>This could result in broken assessments.</ConfirmText>
          <ConfirmText>Are you sure you want to do this?</ConfirmText>
          <ConfirmLabel>Type {QUESTION_STATUS.DECOMMISSIONED} to confirm: </ConfirmLabel>
          <StyledInput value={statusText} onChange={e => setStatusText(e.target.value)} />
        </Modal>
      </>
    );
  };

  return (
    <>
      <QuestionSearchBar roles={roles} onFilterQuestions={onFilterQuestions} onSearchQuestions={onSearchQuestions} />

      {searchSubmitted && showSearchResults(searchInput)}

      {loading && <Loading style={{ marginTop: '1.5em' }} />}

      {!isEmpty(questions) && !loading && showTable()}
      {renderModals()}
    </>
  );
};

// Style Tag element to look similar to button next to it. 
const StyledTag = styled(Tag)`
  padding: 2px 11px;
  font-size: 14px;
  line-height: 1.75;
`;

export const TableHeader = styled.div`
  display: flex;
  background-color: ${colours.lightGrey500};
  padding: 0.5em;

  ${props =>
    props.selected &&
    css`
      background-color: ${colours.blue600};
    `}
`;

export const HeaderText = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: #ffffff;
  background-color: inherit;
  font-size: 1em;
  font-weight: 600;
  margin-left: 0.5em;
`;

const MsgContainer = styled.div`
    margin: 1rem 0 2rem 0;
`;

export const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-item-active {
    background-color: inherit;
  }
`;

const ConfirmLabel = styled.div`
  color: ${colours.navy700};
  font-size: 1em;
  font-weight: 600;
`;

const ConfirmText = styled.div`
  color: ${colours.navy900};
  font-size: 1em;
  font-weight: 500;
`;

const StyledInput = styled(Input)`
  margin-top: 1em;
`;
