import { getConfig } from 'config/get-config';
import { authService } from 'services/auth';
import { SplitFactory } from '@splitsoftware/splitio';

const { SPLIT_API_KEY } = getConfig();

let onReadyPromise = null;

export const getClient = async () => {
  const userId = await authService.getUserIdFromToken();

  const client = SplitFactory({
    core: {
      authorizationKey: SPLIT_API_KEY,
      key: userId
    }
  }).client();
  
  return client.ready()
    .then(() => client);
};

export const initSplitIOClient = () => {
  if (!onReadyPromise) {
    onReadyPromise = getClient();
  }

  return onReadyPromise;
};


export const getFeature = async (featureName, attributes = {}) => {
  return initSplitIOClient()
    .then(client => {
      return client && client.getTreatment(featureName, attributes);
    })
    .catch(err => console.log(featureName, err)); // Stop sdk timeout errors from reaching bugsnag
};