import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colours } from '@a-cloud-guru/rainbow-ui';

const Card = props => (
  <Container>
    <CardTop>
      {props.cardTop}
    </CardTop>
    <CardBottom>
      {props.cardBottom}
    </CardBottom>
  </Container>
);

const CardTop = styled.div`
  padding: 3em 1em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardBottom = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  overflow: hidden;
  background: #fff;
  border-radius: 3px;
  color: #000;
  margin-bottom: 2em;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.2)};
  }
`;

export { Card };