import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import { Page } from 'components/page/Page';
import { Loading } from 'components/loading/Loading';
import { QuestionForm } from './QuestionForm';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { rgba } from 'polished';
import { GQL_EDITOR_QUESTION_PROPERTIES } from './constants';

const GET_QUESTION = gql`
  query ACG_questionById($questionId: String!) {
    ACG_questionById(questionId: $questionId) {
      ${GQL_EDITOR_QUESTION_PROPERTIES}
    }
  }
`;

const getQuestion = (apolloClient, questionId) => {
  if (isEmpty(apolloClient) || isEmpty(questionId)) {
    return null
  }

  return apolloClient
    .query({
      query: GET_QUESTION,
      variables: { questionId },
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => {
      const question = get(data, 'ACG_questionById', {});

      return question;
    })
    .catch(error => {
      console.log("Error: ", error);
    })
};

const EditQuestion = props => {
  const { client, serverState } = props;
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    const id = get(serverState, 'match.params.id', '');
    getQuestion(client, id).then(setQuestion)

    return () => {
      setQuestion(null);
    }
  }, [client, serverState]);

  const displayForm = () => {
    if (!question) {
      return (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      );
    }

    return (
      <QuestionForm
        mode="EDIT"
        initialQuestion={question}
        setInitialQuestion={setQuestion}
        apolloClient={client}
      />
    );
  };

  const shortCode = question && question.questionId ? question.questionId.split('-')[0] : '';

  const breadcrumbs = [{
    link: '/questions',
    text: 'Questions'
  }, {
    text: 'Edit'
  }]

  return <Page title={`Edit Question ${shortCode}`} breadcrumbs={breadcrumbs}>{displayForm()}</Page>;
};

const LoadingContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
  padding: 1em;
`;

export { EditQuestion };
