import React from 'react';
import { isEmpty } from 'lodash';
import showdown from 'showdown';
import TurndownService from 'turndown';
import ReactMarkdown from 'react-markdown';
import sanitize from 'sanitize-html';

const showdownConverter = new showdown.Converter();
const turndownService = new TurndownService();

export const Markdown = ({ markdown }) => <ReactMarkdown source={markdown} />;

export const HTML = ({ html }) => (
  <div
    className="sanitized-html"
    dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
  />
);

export const cleanHtmlString = string => string.replace(/<(.|\n)*?>/g, '');

export const html2Markdown = html =>
  turndownService.turndown(sanitizeHtml(html));

export const markdown2Html = markdown =>
  sanitizeHtml(showdownConverter.makeHtml(markdown));

export const sanitizeHtml = html =>
  sanitize(html, {
    allowedTags: ['p', 'strong', 'em', 'u', 'br', 'pre', 'blockquote', 'code', 'img']
  });

export const isEmptyHtml = html =>
  isEmpty(html)
    ? true
    : isEmpty(
        sanitize(html, {
          allowedTags: [],
          allowedAttributes: {}
        })
      );