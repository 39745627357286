import React from 'react';
import { Layout } from '@a-cloud-guru/rainbow-ui';
import { Nav } from 'components/nav/Nav';
import { Masthead } from 'components/masthead/Masthead';

const { Sider } = Layout;

const Page = props => {
  return (
    <Layout style={{ minHeight: '100vh', background: '#FFF' }}>
      <Sider collapsible width={300}>
        <Nav />
      </Sider>
      <Layout style={{ padding: '20px 28px' }}>
        <Masthead {...props} />
        {props.children}
      </Layout>
    </Layout>
  );
}

export { Page };