import React from 'react';
import { Link } from 'react-router-dom';
import { get, isEmpty, orderBy, uniq } from 'lodash';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { dateFromNow } from 'lib/formatDate';
import { cleanHtmlString } from 'lib/html';
import * as TStyles from './TStyles';
import * as Styles from 'components/questions-styles';
import { QUESTION_STATUS } from 'routes/Questions/Form/constants';

const PAGINATION_LIMIT = 1000;

const sort = params => {
  if (isEmpty(params)) {
    return [];
  }

  const sorted = orderBy(params, [param => param.toLowerCase()], ['asc']);
  return uniq(sorted);
};

export const generateColumns = () => [
  {
    title: 'Question',
    dataIndex: 'question',
    render: question => (
      <Styles.Text14px400Navy900>
        {cleanHtmlString(question.questionText)}
      </Styles.Text14px400Navy900>
    )
  },
  {
    title: 'Role',
    render: renderRoles
  },
  {
    title: 'Skill Level',
    render: renderSkillLevel
  },
  {
    title: 'Status',
    render: renderStatus
  },
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdated',
    render: lastUpdated => (
      <MW125px>
        <Styles.Text14px400Navy900>
          {lastUpdated && dateFromNow(lastUpdated)}
        </Styles.Text14px400Navy900>
      </MW125px>
    )
  },
  {
    title: '',
    dataIndex: 'questionId',
    render: questionId => (
      <Tooltip title="Edit">
        <StyledLink to={`/question/edit/${questionId}`}>
          <i className="fas fa-pen" />
        </StyledLink>
      </Tooltip>
    )
  }
];

export const renderRoles = (value, row, index) => {
  const disciplines = get(row.question, 'disciplines', []);
  if (isEmpty(disciplines)) {
    return null;
  }

  const roles = disciplines.map(
    discipline => discipline.role && discipline.role
  );

  return sort(roles).map((role, index) => (
    <MW125px key={index}>
      <Styles.Text14px400Navy900>{role}</Styles.Text14px400Navy900>
    </MW125px>
  ));
};

export const renderSkillLevel = (value, row, index) => {
  const disciplines = get(row.question, 'disciplines', []);
  if (isEmpty(disciplines)) {
    return null;
  }

  const levels = disciplines.map(
    discipline => discipline.skillLevel && discipline.skillLevel
  );

  return sort(levels).map((level, index) => (
    <MW125px key={index}>
      <Styles.Text14px400Navy900>{level}</Styles.Text14px400Navy900>
    </MW125px>
  ));
};

export const renderStatus = (value, row, index) => (
  <>
    {!isEmpty(row.questionStatus) && <MW125px>{showStatus(row.questionStatus)}</MW125px>}
    {row.isFlaggedForQA && <MW125px><Styles.FlaggedForQA /></MW125px>}
  </>
);

const showStatus = status => {
  if (status === QUESTION_STATUS.DRAFT) {
    return <Styles.Draft />;
  }

  if (status === QUESTION_STATUS.LIVE) {
    return <Styles.Live />;
  }

  if (status === QUESTION_STATUS.QA) {
    return <Styles.QA />;
  }

  if (status === QUESTION_STATUS.HIDDEN) {
    return <Styles.Hidden />;
  }

  if (status === QUESTION_STATUS.DECOMMISSIONED) {
    return <Styles.Decommissioned />;
  }

  // if (status === 'NEED_EDITING') {
  //   return <Styles.NeedsEditing />;
  // }

  // if (status === 'INCOMPLETE') {
  //   return <Styles.Incomplete />;
  // }

  // if (status === 'APPROVED') {
  //   return <Styles.Approved />;
  // }

  return null;
};

const getPagination = ({ current, pageSize, total, onChange }) => {
  const totalOverLimit = total > PAGINATION_LIMIT;

  return {
    current,
    pageSize,
    total: totalOverLimit ? PAGINATION_LIMIT : total,
    onChange,
    showTotal: (total, range) => (
      <>
        {totalOverLimit && (
          <TStyles.TooManyResults>
            <TStyles.TooManyResultsIcon type="info-circle" theme="filled" />
            Displaying first {PAGINATION_LIMIT} results, refine your search to
            view all relevant results.
          </TStyles.TooManyResults>
        )}
        <TStyles.TotalResults>
          Showing {range[0]} - {range[1]} of {total}
        </TStyles.TotalResults>
      </>
    )
  };
};

export const PaginatedTable = props => {
  const { pagination, ...restProps } = props;
  const paginationConfig = pagination ? getPagination(pagination) : null;

  return (
    <TStyles.StyledTable
      {...restProps}
      columns={generateColumns()}
      pagination={paginationConfig}
      overLimit={get(pagination, 'total', 0) > PAGINATION_LIMIT}
    />
  );
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${colours.darkGrey700};
  font-size: 1.125em;
  font-weight: 700;
  text-decoration: none;

  &:focus,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${colours.darkGrey700};
    text-decoration: none;
  }
`;

const MW125px = styled.div`
  margin-left: 0.25em;
  min-width: 125px;
`;
