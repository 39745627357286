// https://quilljs.com/docs/quickstart/
// https://github.com/zenoamaro/react-quill
// https://github.com/domchristie/turndown
// https://github.com/showdownjs/showdown
// https://github.com/rexxars/react-markdown
// https://github.com/apostrophecms/sanitize-html
import 'react-quill/dist/quill.snow.css';
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import ReactQuill, { Quill } from 'react-quill';
import { colours } from '@a-cloud-guru/rainbow-ui';

export class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || ''
    };
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null; // ReactQuill component
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  imageHandler = () => {
    var range = this.quillRef.getSelection();
    var value = prompt('Please insert the image URL here:');
    this.quillRef.insertEmbed(
      range.index,
      'image',
      isEmpty(value) ? '' : value,
      Quill.sources.USER
    );
  };

  modules = {
    toolbar: {
      container: [
        [
          'bold',
          'italic',
          'underline',
          'blockquote',
          'code-block',
          'code',
          'image'
        ]
        // Uncomment these (and formats below) if needed,
        // [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }]
      ],
      handlers: {
        image: this.imageHandler
      }
    }
  };

  formats = [
    'bold',
    'italic',
    'underline',
    'blockquote',
    'code-block',
    'code',
    'image'
    // Uncomment these if needed
    // 'align',
    // 'list',
  ];

  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {})
      };
    }
    return null;
  }

  onChange = content => {
    this.setState({ value: content });
    this.props.onTextChange(content);
  };

  render() {
    return (
      <StyledEditor
        ref={el => {
          this.reactQuillRef = el;
        }}
        placeholder={this.props.placeholder}
        defaultValue={this.state.value}
        modules={this.modules}
        formats={this.formats}
        onChange={content => this.onChange(content)}
      />
    );
  }
}

const StyledEditor = styled(ReactQuill)`
  line-height: 1;

  .ql-editor {
    min-height: 16em;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  .ql-toolbar.ql-snow {
    background-color: ${colours.lightGrey300};
    border-color: ${colours.lightGrey800};
  }

  .has-error & .ql-toolbar {
    background-color: ${colours.red100};
    border-top-color: ${colours.red200};
    border-right-color: ${colours.red200};
    border-left-color: ${colours.red200};
  }

  .has-error & .ql-container {
    border-color: ${colours.red700};
  }
`;
