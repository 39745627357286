import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { useDropzone } from 'react-dropzone';
import { message } from 'antd';
import * as Bluebird from 'bluebird';
import * as LabStyles from 'components/labsstyles';
import { CREATE_METADATA } from 'routes/Questions';

export const ServiceImport = props => {
  const [files, setFiles] = useState([]);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    try {
      setInvalid(false);
      setContent(null);
      setError(null);
      const reader = new FileReader();
      reader.onload = () => {
        const result = _.get(reader, 'result', null);
        if (!_.isEmpty(result) && isJsonString(result)) {
          const services = _.get(JSON.parse(result), 'services', []);
          const formatted = services
            .filter(service => !!service.name)
            .map(service => service.name);

          if (!_.isEmpty(formatted)) {
            setInvalid(false);
            setContent(formatted);
          } else {
            setInvalid(true);
          }
        } else {
          setInvalid(true);
        }
      };
      reader.readAsBinaryString(acceptedFiles[0]);
      setFiles(acceptedFiles);
    } catch (error) {
      console.log('error: ', error);
      setInvalid(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false
  });

  const onUpload = async e => {
    e.preventDefault();
    setError(null);

    if (_.isEmpty(content)) {
      setError('Invalid Service Data Format');
      return;
    }

    setUploading(true);

    try {
      await Bluebird.map(
        content,
        serviceText =>
          props.apolloClient.mutate({
            mutation: CREATE_METADATA,
            variables: { input: { value: serviceText, type: 'SERVICE' } }
          }),
        { concurrency: 30 }
      );
    } catch (error) {
      console.log('Error: ', error);
      setError(error.message);
      message.error('Something went wrong.');
    }

    setUploading(false);
    setInvalid(false);
    setContent(null);
    setFiles([]);

    message.success('Services imported.');
    return;
  };

  return (
    <LabStyles.FileContainer>
      {!_.isEmpty(files[0]) && <div>File: {files[0].name}</div>}
      <Container {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <LabStyles.LabelDesc>Drop the file here ...</LabStyles.LabelDesc>
        ) : (
            <LabStyles.LabelDesc>
              Drag 'n' drop the file here, or click to select the file
          </LabStyles.LabelDesc>
          )}
      </Container>
      {!_.isEmpty(files[0]) && (
        <LabStyles.GreenBtn
          onClick={onUpload}
          hidden={invalid}
          disabled={uploading || invalid}
        >
          Submit{uploading && 'ting'}
        </LabStyles.GreenBtn>
      )}
      {invalid && <LabStyles.FormErrorMsg message="Invalid Service Data Format" />}
      {!_.isEmpty(error) && (
        <>
          <LabStyles.StyledDivider />
          <LabStyles.FormErrorMsg message={error} />
        </>
      )}
    </LabStyles.FileContainer>
  );
};

const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getColor = props => {
  if (props.isDragAccept) {
    return colours.green400;
  }
  if (props.isDragReject) {
    return colours.red700;
  }
  if (props.isDragActive) {
    return colours.blue400;
  }
  return colours.lightGrey300;
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: ${colours.lightGrey200};
  color: ${colours.lightGrey800};
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 1em;
`;
