const { get } = require('lodash');
const { version } = require('../../package.json');

const BUILD_NUMBER = get(process, 'env.BUILD_NUMBER', version);

module.exports = {
  CLOUDINARY_ACCOUNT: 'acloud-guru',
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com',
  ALGOLIA_APP_ID: '2AQ0ZGWJ3U',
  BUILD_NUMBER
};
