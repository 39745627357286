module.exports = {
  AUTH0_CLIENT_ID: 'yChiT2T3LMEdxq49p0Ki3eRp1QJPPgk1',
  AUTH0_DOMAIN: 'acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://acloudguru.auth0.com/api/v2/',
  DOMAIN: 'https://content-admin.acloud.guru',
  GRAPHQL_API_ENDPOINT: 'https://prod-api.acloud.guru/bff/graphql',
  SPLIT_API_KEY: 'ns9p4amquh8spofc0md17t9h5u0n9gcc4o7r',
  ALGOLIA_STAGE: 'prod',
  ALGOLIA_SEARCH_ONLY_API_KEY: 'bd07683ba0f90f58b2ea5def1ec0cc27'
};
