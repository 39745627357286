import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Masthead } from 'components/masthead/Masthead';
import { Dashboard } from 'components/dashboard/Dashboard';

export const Error = (props) => {
  const title = get(props, 'title', '');
  const errorMsg = get(props, 'errorMsg', '');
  return (
    <Container>
      <Masthead title={title} />
      <Body>
        <Dashboard description={errorMsg} />
      </Body>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  background-color: ${colours.lightGrey200};
  padding-bottom: 5em;
`;

const Body = styled.div`
  max-width: 90em;
  margin: -2.5em auto 0;
  padding: 0 2em;
`;