import React, { useState } from 'react';
import styled from 'styled-components';
import * as Styles from 'components/questions-styles';
import { Checkbox, Form } from 'antd';
import { ASSESSMENT_TYPES } from './constants';
import { includes } from 'lodash';
import { DisciplinesSection } from './DisciplinesSection';

const AssessmentSection = props => {
  const { getFieldDecorator, questionInput, onDisciplinesChange, options } = props;
  const [isSkillsAssessment, setIsSkillsAssessment] = useState(includes(questionInput.assessmentType, ASSESSMENT_TYPES.SKILL_ASSESSMENT));

  const onChange = e => {
    setIsSkillsAssessment(e.target.checked);
  };

  return (
    <Container>
      <Styles.SectionLabelLight margin="0 0 0.5em">Assessment Types</Styles.SectionLabelLight>
      <Form.Item>
        {getFieldDecorator('assessmentTypeSkillsAssessment', {
          initialValue: isSkillsAssessment,
          valuePropName: 'checked'
        })(
          <Checkbox onChange={onChange}>Skills Assessment</Checkbox>
        )}
      </Form.Item>
      {
        isSkillsAssessment &&
        <DisciplinesSection
          options={options}
          disciplines={questionInput.disciplines || []}
          getFieldDecorator={getFieldDecorator}
          onDisciplinesChange={onDisciplinesChange}
        />
      }
    </Container>
  )
};

const Container = styled.div`
  padding-right: 2em;
`;

export { AssessmentSection };