import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { Select, Form, Icon } from 'antd';
import * as Styles from 'components/questions-styles';
import { alphabeticallySort } from 'lib/alphabetically-sort';

const { Option } = Select;

const TagsSelector = props => {
  const { title, getFieldDecorator, fieldName, initialValue } = props;
  const options = get(props, 'options', []);
  const sortedOptions = alphabeticallySort(options);

  return (
    <Container {...props}>
      <Styles.SectionLabelLight margin="0 0 1.15rem">
        {title}
      </Styles.SectionLabelLight>
      <StyledFormItem>
        {getFieldDecorator(fieldName, {
          initialValue: initialValue || [],
        })(
          <Select
            mode="multiple"
            placeholder="Please select an option"
            suffixIcon={<Icon type="search" />}
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
          >
            {sortedOptions.map((option, index) => <Option key={index} value={option.value}>{option.value}</Option>)}
          </Select>
        )}
      </StyledFormItem>
    </Container>
  );
};

const Container = styled.div`

`;
const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;
export { TagsSelector };