import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { useDropzone } from 'react-dropzone';
import { Row, Col, message } from 'antd';
import { Page } from 'components/page/Page';
import { Dashboard } from 'components/dashboard/Dashboard';
import * as LabStyles from 'components/labsstyles';
import { isQuestionValid, isQuestionArrayValid } from 'models/ImportedQuestion';
import { ServiceImport } from './ServiceImport';

const BULK_IMPORT_MUTATION = gql`
  mutation ACG_importQuestions($questions: [QuestionInput!]!) {
    ACG_importQuestions(questions: $questions) {
      questionId
    }
  }
`;

export const QuestionImport = props => {
  const [files, setFiles] = useState([]);
  const [questionsAdded, setQuestionsAdded] = useState([]);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    try {
      setInvalid(false);
      setContent(null);
      setError(null);
      setQuestionsAdded([]);
      const reader = new FileReader();
      reader.onload = () => {
        const result = _.get(reader, 'result', null);
        if (!_.isEmpty(result) && isJsonString(result)) {
          const data = _.get(JSON.parse(result), 'AdaptiveAssessment', []);
          const questions = _(data) //wrap object to chain lodash methods
            .mapValues((value, questionId) =>
              _.merge({}, value, { questionId })
            ) // attach id to object
            .values() //get the values of the result
            .value(); //unwrap array of objects

          const formatted = formatData(questions);

          if (isValid(formatted)) {
            setInvalid(false);
            setContent(formatted);
          } else {
            setInvalid(true);
          }
        } else {
          setInvalid(true);
        }
      };
      reader.readAsBinaryString(acceptedFiles[0]);
      setFiles(acceptedFiles);
    } catch (error) {
      console.log('error: ', error);
      setInvalid(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false
  });

  const onUpload = async e => {
    e.preventDefault();
    setError(null);

    if (_.isEmpty(content)) {
      setError('Invalid Data Format');
      return;
    }

    setUploading(true);

    return props.client
      .mutate({
        mutation: BULK_IMPORT_MUTATION,
        variables: { questions: content }
      })
      .then(({ data }) => {
        const added = _.get(data, 'ACG_importQuestions', []);
        setQuestionsAdded(added);
        message.success('Questions imported.');
      })
      .catch(error => {
        console.log('Error: ', error);
        setError(error.message);
        message.error('Something went wrong.');
      })
      .finally(() => {
        setUploading(false);
        setInvalid(false);
        setContent(null);
        setFiles([]);
      });
  };

  return (
    <Page title="Assessments" description="">
      <Row gutter={30}>
        <Col span={24}>
          <Dashboard
            title="Bulk Import"
            description="Import data from a JSON file."
          >
            <LabStyles.HeaderTitle>Service Import</LabStyles.HeaderTitle>
            <ServiceImport apolloClient={props.client} />
            <LabStyles.StyledDivider />
            <LabStyles.HeaderTitle>Question Import</LabStyles.HeaderTitle>
            <LabStyles.FileContainer>
              {!_.isEmpty(files[0]) && <div>File: {files[0].name}</div>}
              <Container {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <LabStyles.LabelDesc>
                    Drop the file here ...
                  </LabStyles.LabelDesc>
                ) : (
                    <LabStyles.LabelDesc>
                      Drag 'n' drop the file here, or click to select the file
                  </LabStyles.LabelDesc>
                  )}
              </Container>
              {!_.isEmpty(files[0]) && (
                <LabStyles.GreenBtn
                  onClick={onUpload}
                  hidden={invalid}
                  disabled={uploading || invalid}
                >
                  Submit{uploading && 'ting'}
                </LabStyles.GreenBtn>
              )}
              {invalid && (
                <LabStyles.FormErrorMsg message="Invalid Data Format" />
              )}
              {!_.isEmpty(questionsAdded) && (
                <>
                  <LabStyles.StyledDivider />
                  <LabStyles.ItemLabel>
                    {questionsAdded.length} questions added:
                  </LabStyles.ItemLabel>
                  <StyledPre>
                    {questionsAdded.map(question => (
                      <LabStyles.LabelDesc key={question.questionId}>
                        {question.questionId}
                      </LabStyles.LabelDesc>
                    ))}
                  </StyledPre>
                </>
              )}
              {!_.isEmpty(error) && (
                <>
                  <LabStyles.StyledDivider />
                  <LabStyles.FormErrorMsg message={error} />
                </>
              )}
            </LabStyles.FileContainer>
          </Dashboard>
        </Col>
      </Row>
    </Page>
  );
};

const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const isValid = data => {
  if (_.isEmpty(data)) {
    return false;
  }
  // Validate data format
  const isArrayValid = isQuestionArrayValid(data);

  return isArrayValid;
};

const formatData = questions => {
  if (_.isEmpty(questions)) {
    return null;
  }

  return questions.map(question => makeQuestionObject(question));
};

const makeQuestionObject = question => {
  const questionObj = {
    questionId: question.hasOwnProperty('questionId')
      ? question.questionId
      : null,
    questionText: question.hasOwnProperty('question')
      ? question.question
      : null,
    editorNote: question.hasOwnProperty('editorNote')
      ? question.editorNote
      : null,
    answers: question.hasOwnProperty('answers')
      ? question.answers
        .filter(answer => !!answer.text)
        .map(answer => ({
          answerId: uuid(),
          text: answer.text,
          correct: answer.hasOwnProperty('correct') ? answer.correct : false
        }))
      : [],
    imageUrl: question.hasOwnProperty('imageUrl') ? question.imageUrl : null,
    explanation: question.hasOwnProperty('explanation')
      ? {
        text: _.isEmpty(question.explanation) ? 'TODO' : question.explanation,
        explanationLinks: !_.isEmpty(question.links)
          ? question.links.map(link => ({
            text: link.title,
            url: link.url
          }))
          : []
      }
      : { text: 'TODO' },
    services: question.hasOwnProperty('services') ? question.services : [],
    disciplines: !_.isEmpty(question.disciplines)
      ? question.disciplines.map(discipline => ({
        role: discipline.discipline,
        skillLevel: convertSkillLevel(discipline.slevel)
      }))
      : [],
    assessmentType: question.hasOwnProperty('assessmentType')
      ? question.assessmentType
      : ['SKILL_ASSESSMENT'],
    cloudProviders: question.hasOwnProperty('cloudProviders')
      ? question.cloudProviders
      : [],
    technologies: question.hasOwnProperty('technologies')
      ? question.technologies
      : [],
    topics: question.hasOwnProperty('topics') ? question.topics : []
  };
  isQuestionValid(questionObj);

  return questionObj;
};

const convertSkillLevel = value => {
  if (!value) {
    return null;
  }

  if (value === 1 || value === '1') {
    return 'Novice';
  }

  if (value === 2 || value === '2') {
    return 'Apprentice';
  }

  if (value === 3 || value === '3') {
    return 'Practitioner';
  }

  if (value === 4 || value === '4') {
    return 'Professional';
  }

  if (value === 5 || value === '5') {
    return 'Guru';
  }

  return null;
};

const getColor = props => {
  if (props.isDragAccept) {
    return colours.green400;
  }
  if (props.isDragReject) {
    return colours.red700;
  }
  if (props.isDragActive) {
    return colours.blue400;
  }
  return colours.lightGrey300;
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: ${colours.lightGrey200};
  color: ${colours.lightGrey800};
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 1em;
`;

const StyledPre = styled.pre`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  text-align: left;
  margin-top: 1.5px;
`;
