import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Button } from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterBox = styled.div`
  background-color: ${colours.lightGrey200};
`;

export const FilterLabel = styled.div`
  color: ${colours.darkGrey900};
  font-size: 1.125em;
  font-weight: 700;
  text-align: left;
`;

export const FilterBtn = styled(Button)`
  background-color: ${colours.lightGrey200};
  border: 0;
  border-radius: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colours.lightGrey500};
    color: ${colours.darkGrey900};
  }
`;