import { useState, useEffect, useCallback } from 'react';
import algoliasearch from 'algoliasearch';
import { getConfig } from 'config/get-config';

const { ALGOLIA_APP_ID, ALGOLIA_STAGE, ALGOLIA_SEARCH_ONLY_API_KEY } = getConfig();

const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_ONLY_API_KEY);
const contentTaggingIndex = algoliaClient.initIndex(`${ALGOLIA_STAGE}_content_tagging`);

const getContentTags = category => {
  contentTaggingIndex.clearCache();
  return contentTaggingIndex.search({
    query: '',
    filters: `category:"${category.name}"`,
    hitsPerPage: 1000
  })
    .then(({ hits }) => {
      return hits || [];
    });
};

export const useContentTags = category => {
  const [tags, setTags] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const refresh = useCallback(() => {
    setLoading(true);
    getContentTags(category)
      .then(setTags)
      .catch(error => setError(error || new Error('Failed to fetch tags.')))
      .finally(() => setLoading(false))
  }, [category]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { tags, loading, error, refresh };
};
