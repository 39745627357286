import React, { useState, useEffect, useCallback } from 'react';
import { get, isEmpty, orderBy } from 'lodash';
import { ItemLabel, StyledDivider } from 'components/questions-styles';
import { Page } from 'components/page/Page';
import { Dashboard } from 'components/dashboard/Dashboard';
import { Loading } from 'components/loading/Loading';
import { SEARCH_QUESTION } from './Pagination';

export const sortRoles = params => {
  if (isEmpty(params)) {
    return [];
  }

  const sorted = orderBy(params, [param => param.name.toLowerCase()], ['asc']);
  return sorted;
};

export const QuestionBankReport = props => {
  const [loading, setLoading] = useState(true);
  const [professionalData, setProfessionalData] = useState(null);
  const [practitionerData, setPractitionerData] = useState(null);
  const [apprenticeData, setApprenticeData] = useState(null);
  const [noviceData, setNoviceData] = useState(null);
  const [guruData, setGuruData] = useState(null);
  
  const getQuestions = useCallback( 
    (searchInput = { query: '', filters: '' } ) => {
      setLoading(true);
      return props.client
        .query({
          query: SEARCH_QUESTION,
          variables: {
            searchInput
          },
          fetchPolicy: 'no-cache'
        })
        .then(({ data }) => {
          return get(data, 'ACG_searchQuestions', null);
        })
        .catch(error => console.log('Error: ', error));
    }, 
    [props.client]
  );

  useEffect(() => {
    setLoading(true);
    const PractitionerPromise = getQuestions({
      query: '',
      filters: 'question.disciplines.skillLevel:Practitioner AND questionStatus:LIVE'
    });
    const ProfessionalPromise = getQuestions({
      query: '',
      filters: 'question.disciplines.skillLevel:Professional AND questionStatus:LIVE'
    });
    const ApprenticePromise = getQuestions({
      query: '',
      filters: 'question.disciplines.skillLevel:Apprentice AND questionStatus:LIVE'
    });
    const NovicePromise = getQuestions({
      query: '',
      filters: 'question.disciplines.skillLevel:Novice AND questionStatus:LIVE'
    });
    const GuruPromise = getQuestions({
      query: '',
      filters: 'question.disciplines.skillLevel:Guru AND questionStatus:LIVE'
    });

    Promise.all([PractitionerPromise, ProfessionalPromise, ApprenticePromise, NovicePromise, GuruPromise])
      .then(([Practitioner, Professional, Apprentice, Novice, Guru]) => {
        setPractitionerData(Practitioner);
        setProfessionalData(Professional);
        setApprenticeData(Apprentice);
        setNoviceData(Novice);
        setGuruData(Guru);
      })
      .catch(e => console.error(e))
      .finally(() => {
        setLoading(false);
      });
  }, [getQuestions]);

  if (loading) {
    return <Loading style={{ marginTop: '1.5em' }} />;
  }

  const practitioner = get(practitionerData, 'questions', []);
  const practitionerRoles = get(practitionerData, 'facets.roles', []);

  const professional = get(professionalData, 'questions', []);
  const professionalRoles = get(professionalData, 'facets.roles', []);

  const apprentice = get(apprenticeData, 'questions', []);
  const apprenticeRoles = get(apprenticeData, 'facets.roles', []);

  const novice = get(noviceData, 'questions', []);
  const noviceRoles = get(noviceData, 'facets.roles', []);

  const guru = get(guruData, 'questions', []);
  const guruRoles = get(guruData, 'facets.roles', []);

  return (
    <Page title="Question Bank Report" description="">
      <Dashboard title="Number of LIVE questions per skill level and role:" description="">
        <ItemLabel>Skill Level: Novice ({novice.length})</ItemLabel>
        {sortRoles(noviceRoles).map((role, index) => (
          <div key={index}>
            {role.name}: {role.count}
          </div>
        ))}
        <StyledDivider />
        <ItemLabel>Skill Level: Apprentice ({apprentice.length})</ItemLabel>
        {sortRoles(apprenticeRoles).map((role, index) => (
          <div key={index}>
            {role.name}: {role.count}
          </div>
        ))}
        <StyledDivider />
        <ItemLabel>Skill Level: Practitioner ({practitioner.length})</ItemLabel>
        {sortRoles(practitionerRoles).map((role, index) => (
          <div key={index}>
            {role.name}: {role.count}
          </div>
        ))}
        <StyledDivider />
        <ItemLabel>Skill Level: Professional ({professional.length})</ItemLabel>
        {sortRoles(professionalRoles).map((role, index) => (
          <div key={index}>
            {role.name}: {role.count}
          </div>
        ))}
        <StyledDivider />
        <ItemLabel>Skill Level: Guru ({guru.length})</ItemLabel>
        {sortRoles(guruRoles).map((role, index) => (
          <div key={index}>
            {role.name}: {role.count}
          </div>
        ))}
      </Dashboard>
    </Page>
  );
};
