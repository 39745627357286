import gql from 'graphql-tag';
import { getSignedUrl, uploadToS3 } from './s3upload';

const GET_ASSET_UPLOAD_URL = gql`
  query assetUploadUrl($filename: String!) {
    assetUploadUrl(filename: $filename) {
      url
    }
  }
`;

const s3ImageUpload = async (apolloClient, file, onUploadProgress) => {
  const result = await getSignedUrl(
    apolloClient,
    file.name,
    GET_ASSET_UPLOAD_URL
  );

  const { assetUploadUrl } = result;

  if (!assetUploadUrl.url) {
    throw new Error('Could not get signed url for upload.');
  }

  const signedUrl = assetUploadUrl.url;
  const url = await uploadToS3(signedUrl, file, onUploadProgress);

  return url;
};

export { s3ImageUpload };
