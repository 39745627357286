import React from 'react';
import styled from 'styled-components';
import { Form, Radio } from 'antd';
import { cloneDeep, get } from 'lodash';
import { DEFAULTS } from './constants';
import * as Styles from 'components/questions-styles';
import { alphabeticallySort } from 'lib/alphabetically-sort';

const DisciplinesSection = props => {
  const { disciplines, onDisciplinesChange, getFieldDecorator, options } = props;
  const roles = get(options, 'roles', []);
  const sortedRoles = alphabeticallySort(roles);
  const defaultRole = get(sortedRoles, '[0][value]', DEFAULTS.ROLE);

  const remove = index => {
    const newDisciplines = cloneDeep(disciplines);
    newDisciplines.splice(index, 1);
    onDisciplinesChange(newDisciplines);
  };

  const add = () => {
    const newDisciplines = cloneDeep(disciplines);
    newDisciplines.push({ skillLevel: DEFAULTS.SKILL_LEVEL, role: defaultRole });
    onDisciplinesChange(newDisciplines);
  };

  const onSkillLevelChange = (event, index) => {
    const newDisciplines = cloneDeep(disciplines);
    newDisciplines[index].skillLevel = event.target.value;
    onDisciplinesChange(newDisciplines);
  };

  const onRoleChange = (event, index) => {
    const newDisciplines = cloneDeep(disciplines);
    newDisciplines[index].role = event.target.value;
    onDisciplinesChange(newDisciplines);
  };

  const showDisciplines = disciplines => {
    return disciplines.map((discipline, index) => {
      return (
        <Styles.DisciplineContainer key={index}>
          <Styles.ItemLabel>Skill Level</Styles.ItemLabel>
          <Form.Item>
            {getFieldDecorator(`discipline-${index}-skillLevel`, {
              initialValue: discipline.skillLevel || DEFAULTS.SKILL_LEVEL
            })(
              <Radio.Group onChange={(event) => onSkillLevelChange(event, index)}>
                <StyledRadio value="Novice">Novice</StyledRadio>
                <StyledRadio value="Apprentice">Apprentice</StyledRadio>
                <StyledRadio value="Practitioner">Practitioner</StyledRadio>
                <StyledRadio value="Professional">Professional</StyledRadio>
                <StyledRadio value="Guru">Guru</StyledRadio>
              </Radio.Group>,
            )}
          </Form.Item>

          <Styles.ItemLabel>Role</Styles.ItemLabel>
          <Form.Item>
            {getFieldDecorator(`discipline-${index}-role`, {
              initialValue: discipline.role || defaultRole
            })(
              <Radio.Group onChange={(event) => onRoleChange(event, index)}>
                {sortedRoles.map((role, index) => <StyledRadio key={index} value={role.value}>{role.value}</StyledRadio>)}
              </Radio.Group>
            )}
          </Form.Item>
          <Styles.ActionCorner>
            <Styles.DeleteIconBtn onClick={() => remove(index)} />
          </Styles.ActionCorner>
        </Styles.DisciplineContainer>
      );
    });
  };

  return (
    <>
      {showDisciplines(disciplines)}
      <Form.Item>
        <Styles.GreyBtn onClick={add}>
          <Styles.StyledI className="fa fa-plus" /> New Discipline
        </Styles.GreyBtn>
      </Form.Item>
    </>
  );
};

const StyledRadio = styled(Radio)`
  display: block;
  margin: 0 0 0.57em 0;

  &:last-child {
    margin: 0;
  }
`;

export { DisciplinesSection };
