import React from 'react';
import styled from 'styled-components';
import { find, get } from 'lodash';
import { Page } from 'components/page/Page';
import { colours, Flexbox } from '@a-cloud-guru/rainbow-ui';
import { TAG_CATEGORY } from 'components/tag/constants';
import { TagsSearch } from './TagsSearch';
import { TagsTable } from './TagsTable';
import { AddTagsModal } from './Modals/AddTagsModal';

const getCategoryById = categoryId => {
  return find(TAG_CATEGORY, { id: categoryId });
}

export const TagsCategory = props => {
  const { serverState } = props;
  const categoryId = get(serverState, 'match.params.category', null);
  const category = getCategoryById(categoryId);

  console.log(category)

  const makeActionElement = () => {
    return (
      <Flexbox alignItems="center">
        <TagsSearch category={category} onSearch={onSearch} />
        <AddTagsModal category={category} />
      </Flexbox>
    );
  };

  const onSearch = event => {
    console.log(`searched for ${event.target.value}`);
  };

  if (!category) {
    return (
      <Page
        title="Tag Category Not Found"
        breadcrumbs={[{ text: 'Reference Data', link: '#' }, { text: 'Tags', link: '/tags' }, { text: 'Unknown Tag Category' }]}
        description={<Description>Please make sure the URL is correct.</Description>}
      >
      </Page>
    );
  }

  return (
    <Page
      title={`${category.name} Tags`}
      breadcrumbs={[{ text: 'Reference Data', link: '#' }, { text: 'Tags', link: '/tags' }, { text: category.name }]}
      actionElement={makeActionElement()}
    >
      <TagsTable category={category} />
    </Page>
  );
};

const Description = styled.p`
  color: ${colours.darkGrey900};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 10px 0 0;
`;
