import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Page } from 'components/page/Page';
import { Row, Col, Avatar } from 'antd';
import { Card } from 'components/card/Card';
import { ImageOptimizer } from 'components/image/ImageOptimizer';
import { Loading } from 'components/loading/Loading';
import { rgba } from 'polished';
import { colours } from '@a-cloud-guru/rainbow-ui';

const GET_LEARNING_PATHS = gql`
  query {
    allLearningPaths {
      id
      name
      tileUrl
      shortDescription
    }
  }
`;

const LearningPaths = props => {
  const [learningPaths, setLearningPaths] = useState(null);

  useEffect(() => {
    const getLearningPaths = () => {
      return props.client
        .query({ query: GET_LEARNING_PATHS })
        .then(({ data }) => {
          return data.allLearningPaths || null;
        })
    }

    let isSubscribed = true;
    if (!learningPaths && isSubscribed) {
      getLearningPaths()
        .then(setLearningPaths);
    }

    return () => (isSubscribed = false);
  }, [learningPaths, props.client]);

  const displayLearningPaths = () => {
    if (!learningPaths) {
      return (
        <Col span={24}>
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        </Col>
      );
    }

    return learningPaths.map(learningPath => (
      <Col span={6} key={learningPath.id}>
        <Link to={`/learning-paths/${learningPath.id}`}>
          <Card
            cardTop={
              <ImageOptimizer width={150} height={150}>
                {({ buildOptimizedSrc }) =>
                  <Avatar src={buildOptimizedSrc(learningPath.tileUrl)} size={150} />
                }
              </ImageOptimizer>
            }
            cardBottom={learningPath.name}
          />
        </Link>
      </Col>
    ));
  }

  return (
    <Page title="Manage Learning Paths" breadcrumbs={[{ text: 'Learning Paths' }]}>
      <Container>
        <Row gutter={30}>
          {displayLearningPaths()}
        </Row>
      </Container>
    </Page>
  );
}

const Container = styled.div`
  a:hover, a:focus {
    text-decoration: none;
  }
`;

const LoadingContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
  padding: 1em;
`;

export { LearningPaths };
