import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';
import { authService } from 'services/auth';
import { getConfig } from 'config/get-config';
import { name } from '../../package.json';

const { BUILD_NUMBER, GRAPHQL_API_ENDPOINT, STAGE } = getConfig();

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const authLink = () =>
  setContext(() => {
    return authService.getToken().then(token => {
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : `Bearer `,
          'apollographql-client-name': `${name}-${STAGE}`,
          'apollographql-client-version': BUILD_NUMBER
        }
      };
    });
  });

const httpLink = () => {
  return new HttpLink({
    credentials: 'same-origin',
    uri: GRAPHQL_API_ENDPOINT
  });
};

const makeApolloClient = () =>
  new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: ApolloLink.from([authLink(), httpLink()]),
    cache: new InMemoryCache({ fragmentMatcher })
  });

const client = makeApolloClient();

const getApolloClient = () => client;

export { getApolloClient };
