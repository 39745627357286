import React from 'react';
import styled from 'styled-components';
import { Input, Icon } from '@a-cloud-guru/rainbow-ui';

export const TagsSearch = props => {
  const { category, onSearch } = props;

  return (
    <Container>
      <Input
        placeholder={`Search ${category.name.toLocaleLowerCase()}`}
        prefix={<Icon type="search" />}
        onPressEnter={onSearch}
      />
    </Container>
  );
};

const Container = styled.div`
  flex-shrink: 0;
  width: 266px;
`;