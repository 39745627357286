import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Spinner } from 'components/spinner'

const Loading = props => {
  return (
    <Container {...props}>
      <Spinner />
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const LoadingContainer = styled.div`
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 24px 0 ${rgba(colours.navyDark, 0.06)};
  padding: 1em;
`;

export { Loading, LoadingContainer };
